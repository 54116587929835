// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* General Styles */
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: #FFF2F2;
  margin: 0;
  padding: 0;
}

/* Styles specific to the AboutUs component */
.about-us-container {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #FFF2F2;
  margin: 20px auto;
  padding: 20px;
  max-width: 90%;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-us-header h1 {
  font-size: 2em;
  color: darkblue;
  margin-bottom: 10px;
  text-align: center;
}

.about-us-header p {
  font-size: 1.2em;
  color: #666;
  text-align: center;
}

.about-us-section {
  margin-bottom: 20px;
}

.about-us-section h2 {
  font-size: 1.5em;
  color: darkblue;
  margin-bottom: 10px;
}

.about-us-section p {
  font-size: 1em;
  margin-bottom: 15px;
}

.about-us-section ul,
.about-us-section ol {
  padding-left: 20px;
}

.about-us-section ul li,
.about-us-section ol li {
  margin-bottom: 10px;
  color: #444;
}

.about-us-section strong {
  color: #e60000;
}
`, "",{"version":3,"sources":["webpack://./src/components/AboutUs.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;EACE,8BAA8B;EAC9B,gBAAgB;EAChB,yBAAyB;EACzB,SAAS;EACT,UAAU;AACZ;;AAEA,6CAA6C;AAC7C;EACE,8BAA8B;EAC9B,gBAAgB;EAChB,WAAW;EACX,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,cAAc;EACd,sBAAsB;EACtB,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,cAAc;EACd,eAAe;EACf,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;;EAEE,kBAAkB;AACpB;;AAEA;;EAEE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,cAAc;AAChB","sourcesContent":["/* General Styles */\r\nbody {\r\n  font-family: Arial, sans-serif;\r\n  line-height: 1.6;\r\n  background-color: #FFF2F2;\r\n  margin: 0;\r\n  padding: 0;\r\n}\r\n\r\n/* Styles specific to the AboutUs component */\r\n.about-us-container {\r\n  font-family: Arial, sans-serif;\r\n  line-height: 1.6;\r\n  color: #333;\r\n  background-color: #FFF2F2;\r\n  margin: 20px auto;\r\n  padding: 20px;\r\n  max-width: 90%;\r\n  border: 1px solid #ddd;\r\n  border-radius: 5px;\r\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.about-us-header h1 {\r\n  font-size: 2em;\r\n  color: darkblue;\r\n  margin-bottom: 10px;\r\n  text-align: center;\r\n}\r\n\r\n.about-us-header p {\r\n  font-size: 1.2em;\r\n  color: #666;\r\n  text-align: center;\r\n}\r\n\r\n.about-us-section {\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.about-us-section h2 {\r\n  font-size: 1.5em;\r\n  color: darkblue;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.about-us-section p {\r\n  font-size: 1em;\r\n  margin-bottom: 15px;\r\n}\r\n\r\n.about-us-section ul,\r\n.about-us-section ol {\r\n  padding-left: 20px;\r\n}\r\n\r\n.about-us-section ul li,\r\n.about-us-section ol li {\r\n  margin-bottom: 10px;\r\n  color: #444;\r\n}\r\n\r\n.about-us-section strong {\r\n  color: #e60000;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
