import React, { useState } from 'react';
import Slider from 'react-slick';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ArticleDetailsDSpace from '../pages/ArticleDetails_DSpace';
import './Cardgrid.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTheme } from '@mui/material/styles';

const Cardgrid = () => {

  const theme = useTheme(); // Access the theme

  // Manually defined books
  const books = [
    {
      id: 1,
      title: 'Namo Vani',
      author: 'Ed. Arun Anand',
      uuid: '91f30d4b-42db-4d7c-823c-32db053ba758',
      thumbnail: '/9789350486184.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 2,
      title: 'Super Speed English Speaking Course',
      author: 'Rashmeet Kaur',
      uuid: 'f32c30ca-599b-4a06-960a-aaa9cc419229',
      thumbnail: '/9788173159015.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 3,
      title: 'Itihaas-Purush Subhash',
      author: 'Shrikrishna Saral',
      uuid: '7cdf48db-7d86-4ef2-912c-97c07ae2dc95',
      thumbnail: '/8188266177.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 4,
      title: 'How To Teach A Foreign Language',
      author: 'Otto Jespersen',
      uuid: '87604517-53eb-4e23-861b-94cfab90ede8',
      thumbnail: '/9788184306538.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 5,
      title: 'The Life and Times of Sri Aurobindo Ghosh',
      author: 'Charlotte Maria Tucker',
      uuid: '5de8fe2b-3a29-420f-8e82-141633663d26',
      thumbnail: '/9788184303681.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 6,
      title: 'The Art of Logical Thinking or The Law of Reasoning',
      author: 'William Walker Atkinson',
      uuid: '94245b3a-45cb-4d95-a984-353a5b69a927',
      thumbnail: '/9788184305065.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 7,
      title: 'A Wreath of Indian Stories',
      author: 'Charlotte Maria Tucker',
      uuid: '2c0b02d7-d923-4049-8bf5-f52ef35679e7',
      thumbnail: '/Gutenberg37599.pdf.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 8,
      title: 'Aao Badlein Tasveer',
      author: 'Anita Prabhakar',
      uuid: 'dae3aa47-3791-4687-bf5a-84feef72b97b',
      thumbnail: '/9789383110599.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 9,
      title: 'Bharat Ke Mahan Sant',
      author: 'Baldev Vanshi',
      uuid: '0fc812eb-133a-4495-80ef-148a53cf1d60',
      thumbnail: '/8188266752.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 10,
      title: 'An Ideal Husband',
      author: 'Oscar Wilde',
      uuid: 'e4f83502-19c6-49c4-809d-c2a9c9886867',
      thumbnail: '/9788184305650.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 11,
      title: 'Management Lesson from the Films of Big B',
      author: 'Harmik Vaishnav',
      uuid: '5b3d01f3-6cbd-4bf0-8c6b-994ad6d17b8c',
      thumbnail: '/9788184304053.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 12,
      title: 'Gurudev',
      author: 'Dinkar Joshi',
      uuid: '9fe7406e-effb-4f34-8e71-7c820ba8d550',
      thumbnail: '/8188140783.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 13,
      title: 'Gyanmarg Karmayogi Swami Vivekananda',
      author: 'Deokinandan Gautam',
      uuid: '74da674f-df59-483a-94c5-0bd43aec2c0a',
      thumbnail: '/9789350482643.jpg', // Provide the path to the thumbnail image
    },
    // Add more books as needed
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBookUuid, setSelectedBookUuid] = useState(null);

  const handleOpenModal = (uuid) => {
    setSelectedBookUuid(uuid);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedBookUuid(null);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Show 6 books per row
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 4 } },
      { breakpoint: 768, settings: { slidesToShow: 3 } },
      { breakpoint: 480, settings: { slidesToShow: 2 } },
    ],
  };

  return (
    <div className="cardgrid-container">
      <h3>Flagship Leisure Collection eBooks</h3>
      <Slider {...sliderSettings}>
        {books.map((book) => (
          <div key={book.id} className="book-card">
            <img src={book.thumbnail} alt={book.title} className="book-thumbnail" />
            <p
              className="book-title"
              onClick={() => handleOpenModal(book.uuid)}
            >
              {book.title}
            </p>
            <p className="book-author">by {book.author}</p>
          </div>
        ))}
      </Slider>

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="article-details-title"
        aria-describedby="article-details-description"
      >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              right: '1%',
              transform: 'translate(0%, -50%)',
              width: '50%',
              bgcolor: '#f0fafa',
              boxShadow: 24,
              p: 1,
              overflow: 'auto',
              maxHeight: '100vh',
              [theme.breakpoints.down('md')]: {
                width: '90%', // Width adjustment for medium screens
              },
              [theme.breakpoints.down('sm')]: {
                width: '90%', // Width adjustment for small screens
                maxHeight: '70vh', // Adjust height for smaller screens
              },
            }}
          >
          {selectedBookUuid && (
            <ArticleDetailsDSpace
              selectedArticleId={selectedBookUuid}
              closeModal={handleCloseModal}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default Cardgrid;
