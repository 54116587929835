import React from 'react';
import './Footer.css'; // Import a CSS file for styles
import clientLogo from '../assets/UPSIFS_Logo.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        {/* Column 1: Logo and University Info */}
        <div className="column">
          <img src={clientLogo} alt="Logo" className="logo" />
          <p className="footer-text">
            Uttar Pradesh State Institute of Forensic Science, Aurawan, P.O. Banthra,<br />
            Lucknow- 226401, UP, India<br />
            Phone: +91-78390-01310<br />
            Email: upsifsup@gmail.com
          </p>
        </div>

        {/* Column 2: Recent Posts */}
        <div className="column">
          <h4>About Us</h4>
          <p className="footer-text">
          Established in 2023, the UPSIFS campus is an innovative campus focused on Forensic Science learning including Cyber Security, DNA Forensics, Forensic Document Examination, Forensic Ballistics etc.
          </p>
          <p className="footer-text">
            <li>School of Forensic Science</li>
            <li>School of Legal Studies</li>
            <li>School of Science and Technology</li>
          </p>
        </div>

        {/* Column 3: Main Menu */}
        <div className="column">
          <h4>Main Menu</h4>
          <ul>
            <li><a href="#">Home</a></li>
            <li><a href="Browse_All?query=*">E-Resources</a></li>
            <li><a href="List_All">A-Z List</a></li>
            <li><a href="AboutUs">About Us</a></li>
            <li><a href="ContactUs">Contact Us</a></li>
          </ul>
        </div>

        {/* Column 4: Useful Links */}
        <div className="column">
          <h4>Useful Links</h4>
          <ul>
            <li><a href="https://isea.gov.in/" target="_blank">Information Security Education and Awareness (ISEA)</a></li>
            <li><a href="https://cybercrime.gov.in/Default.aspx" target="_blank">National Cyber Crime Reporting Portal</a></li>
            <li><a href="https://www.advocatekhoj.com/library/index.php" target="_blank">Advocate Khoj</a></li>
            <li><a href="https://indiankanoon.org/" target="_blank">Indian Kanoon</a></li>
            <li><a href="https://doaj.org/" target="_blank">DOAJ – Directory of Open Access Journals</a></li>
          </ul>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="footer-bottom">
        <p>© 2024 Uttar Pradesh State Institute of Forensic Science, Lucknow, India. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
