import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import SearchBox1 from '../components/SearchBox1';
import SearchResults from '../components/SearchResults_JGate';
import Pagination from '../components/Pagination';
import SortBy from '../components/SortBy';
import Facets from '../components/Facets';
import ListIcon from '@mui/icons-material/ViewList';
import GridIcon from '@mui/icons-material/ViewModule';
import LoginModal from '../components/LoginModal';
import OpenAIResponse from '../components/OpenAIResponse';
import fetchJGateResults from '../components/fetchJGateResults';
import { useTheme } from '@mui/material/styles';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

import { Doughnut } from 'react-chartjs-2';
import ArticleDetailsDSpace from '../pages/ArticleDetails_DSpace'; // Import the ArticleDetailsDSpace component


import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  Modal,
  Paper,
  IconButton, 
  Tabs,
  Tab,
  useMediaQuery,
  MenuItem,
  Select,
} from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';



// Register the required components
ChartJS.register(ArcElement, Tooltip, Legend);  




// Function to convert search results to CSV format
const convertResultsToCSV = (results) => {
  const headers = ['Title', 'Abstract', 'Authors', 'Year', 'Source'];
  const rows = results.map(result => [
    `"${result.title}"`,
    `"${result.abstract}"`,
    `"${result.authors}"`,
    `"${result.year}"`,
    `"${result.source}"`,
  ]);

  const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
  return csvContent;
};

// Function to handle download
const handleDownload = (results) => {
  const csvData = convertResultsToCSV(results);
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'search_results.csv';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};



const Journal_Articles = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const initialQuery = searchParams.get('query') || ''; 
  const [query, setQuery] = useState(initialQuery);

  const [selectedDatabase, setSelectedDatabase] = useState(
    location.state?.selectedDatabase || 'All Databases'
  );


  const isMobile = useMediaQuery('(max-width:768px)'); // Adjust breakpoint as needed
  const [tabValue, setTabValue] = useState(0);
  

  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false); // State to control modal open/close
  const [selectedArticleId, setSelectedArticleId] = useState(null); // State to store selected article ID
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [viewMode, setViewMode] = useState('list');
  const [sortOrder, setSortOrder] = useState('sjr'); // Default to 'sjr'
  const [selectedSort, setSelectedSort] = useState({ field: 'score', direction: 'DESC' });
  const [selectedFilters, setSelectedFilters] = useState({});

  const theme = useTheme(); // Access the theme

    // Check if user is logged in using localStorage
    const userLoggedIn = localStorage.getItem('token') ? true : false;
    
    const [facets, setFacets] = useState({
      journals: {},
      authors: {},
      subjects: {},
      publishers: {},
      countries: {},
    });




  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  

  const resultsPerPage = 20; // Define results per page


 
  const [showSortMenu, setShowSortMenu] = useState(false);

  const toggleSortMenu = () => {
    setShowSortMenu(prev => !prev);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

 

  const fetchResults = useCallback(async (searchQuery, pageNumber = 1, filters = {}, sorting = selectedSort) => {
    setLoading(true);
    setError(null);
  
    try {
      // Prepare sorting and pagination parameters
      const resultsPerPage = 20;
      const sortParams = {
        field: sorting.field || 'score',
        direction: sorting.direction || 'DESC',
      };
  
      const response = await fetchJGateResults(searchQuery, pageNumber, sortParams, resultsPerPage);
      
      const { results = [], total = 0, facets = { journals: {}, subjects: {}, publishers: {}, countries: {} } } = response;
      
      setResults(results);
      setTotalResults(total);
      setFacets(facets);

      setTotalResults(total);
      setTotalPages(Math.ceil(total / resultsPerPage));
    } catch (error) {
      console.error('Error fetching results:', error);
      setError('Failed to fetch journal articles. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [selectedSort]);
  


  const handleFacetSelection = (facetName, value) => {
    const newFilters = { ...selectedFilters };
  
    if (newFilters[facetName]?.includes(value)) {
      newFilters[facetName] = newFilters[facetName].filter(item => item !== value);
      if (newFilters[facetName].length === 0) delete newFilters[facetName];
    } else {
      newFilters[facetName] = [...(newFilters[facetName] || []), value];
    }
  
    setSelectedFilters(newFilters);
    fetchResults(query, 1, newFilters); // Fetch results with new filters
  };
  



  const calculateRange = () => {
    const startIndex = (page - 1) * resultsPerPage + 1;
    const endIndex = Math.min(page * resultsPerPage, totalResults); // Use totalResults for the upper bound
    return totalResults > 0 ? `${startIndex}-${endIndex}` : '0'; // Ensure it doesn't show "1-0"
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchResults(query, newPage, selectedFilters);
  };
  
  const handleSortChange = (newSortOrder) => {
    setSortOrder(newSortOrder);
    
    let sortField = 'score';
    let sortDirection = 'DESC';
  
    if (newSortOrder === 'alphabetical') {
      sortField = 'dc.title';
      sortDirection = 'ASC';
    } else if (newSortOrder === 'latest') {
      sortField = 'dc.date.issued';
      sortDirection = 'DESC';
    }
  
    // Update sorting state
    setSelectedSort({ field: sortField, direction: sortDirection });
  
    // Update the URL for sorting

    searchParams.set('query', query);
    searchParams.set('sort', `${sortField},${sortDirection}`);
    navigate(`/Journal_Articles?${searchParams.toString()}`);
  
    // Fetch results with new sorting
    fetchResults(query, page, selectedFilters, { field: sortField, direction: sortDirection });
  };

  
// Refetch results when query or page changes
useEffect(() => {
  const fetchAndSetData = async () => {
    try {
      const { results, total, facets } = await fetchJGateResults(query, page, selectedFilters);
      setResults(results);
      setTotalResults(total);
      setFacets(facets); // Set facets here
      console.log('Facets:', facets);
      console.log('Subjects:', facets?.subjects);
      console.log('Publishers:', facets?.publishers);
      console.log('Countries:', facets?.countries);
    } catch (error) {
      console.error('Error fetching results:', error);
    }
  };
  fetchAndSetData();
}, [query, page, selectedFilters]);

 



  

 


  const StyledContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(4),
  }));


  const SearchResultsWrapper = styled(Box)(({ theme }) => ({
    width: '70%', // Default for larger screens
    paddingLeft: theme.spacing(2),
    minHeight: '500px',
    [theme.breakpoints.down('md')]: {
      width: '100%', // Full width on medium screens or smaller
      paddingLeft: 0,
    },
  }));

  const YouTubeResultsWrapper = styled(Box)(({ theme }) => ({
    width: '30%', // Default for larger screens
    paddingLeft: theme.spacing(2),
    minHeight: '500px',
    [theme.breakpoints.down('md')]: {
      width: '100%', // Full width on medium screens or smaller
      paddingLeft: 0,
    },
  }));


  // Function to handle title click
  const onTitleClick = (result) => {
    window.open(result.detailLink, '_blank'); // Opens in a new tab
  };


const handleLoginSuccess = () => {
  setIsLoginModalOpen(false);
  localStorage.setItem('token', 'user-token'); // Save token (mocking login success)
  
  const redirectInfo = JSON.parse(localStorage.getItem('redirectAfterLogin'));
  if (redirectInfo) {
    localStorage.removeItem('redirectAfterLogin'); // Clear the stored redirect info

    // Redirect to the stored action
    if (redirectInfo.source === 'Journal Articles') {
      window.open(redirectInfo.detailLink, '_blank');
    } else if (['eBooks', 'Leisure Readings', 'Forensic Science eBooks', 'Law eBooks', 'Cyber Security eBooks'].includes(redirectInfo.source)) {
      const id = redirectInfo.detailLink.split('/').pop();
      setSelectedArticleId(id);
      setArticleSource('eBooks');
      setModalOpen(true);
    }
  } else {
    window.location.reload(); // Reload to refresh state
  }
};




const [articleSource, setArticleSource] = useState(''); // Track article source type


  return (
    <div style={{ backgroundColor: '#FFE6E6', minHeight: '100vh', minWidth: '100vh' }} className="flex flex-col">
    <StyledContainer>
    
    <SearchBox1
      onSearch={(searchQuery) => {
        const sanitizedQuery = (searchQuery || '').toString().trim();
        setQuery(sanitizedQuery);

        const searchParams = new URLSearchParams();
        searchParams.set('query', sanitizedQuery);


          // Conditional navigation based on database selection
          if (selectedDatabase === 'Journal Articles') {
            navigate(`/Journal_Articles?query=${encodeURIComponent(sanitizedQuery)}`);
          } else if (selectedDatabase === 'eJournals') {
            navigate(`/EJournals?query=${encodeURIComponent(sanitizedQuery)}`);
          } else if (selectedDatabase === 'eBooks') {
            navigate(`/EBooks?query=${encodeURIComponent(sanitizedQuery)}`);
          } else {
            navigate(`/single-search?query=${encodeURIComponent(sanitizedQuery)}`);
          }

        // Fetch results with the new query
        fetchResults(sanitizedQuery, 1, selectedFilters);
        //fetchFacets(sanitizedQuery, selectedFilters);
      }}
      initialQuery={query || ''}
      selectedDatabase="Journal Articles"  // Set default selection
    />

      <br></br>

    {loading && <CircularProgress />}
      {error && (
        <Snackbar open autoHideDuration={6000}>
        </Snackbar>
      )}      

{isMobile ? (
          <>
            {/* Tabs for mobile view */}
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="Search Results" />
              <Tab label="Filter Results" />
            </Tabs>

            {tabValue === 0 && (
              <SearchResultsWrapper>
                <SearchResults
                  results={results}
                  viewMode={viewMode}
                  onTitleClick={(result) => window.open(result.detailLink, '_blank')}
                />
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </SearchResultsWrapper>
            )}
            {tabValue === 1 && (
              <YouTubeResultsWrapper>
                <Paper elevation={1} sx={{ p: 2, mt: 1, backgroundColor: '#FFCCCC' }}>


                <Typography variant="h6">Filter by:</Typography>

                  {/* Subjects */}
                  <Typography variant="subtitle1">Subjects</Typography>
                    {facets?.subjects && Object.entries(facets.subjects).map(([subject, count]) => (
                      <div key={subject}>
                        <input
                          type="checkbox"
                          id={`subject-${subject}`}
                          checked={selectedFilters.subjects_name_l3?.includes(subject) || false}
                          onChange={() => handleFacetSelection('subjects_name_l3', subject)}
                        />
                        <label htmlFor={`subject-${subject}`}>
                          {subject} ({count})
                        </label>
                      </div>
                    ))}

                  {/* Publishers */}
                  <Typography variant="subtitle1">Publishers</Typography>
                  {facets.publishers && Object.entries(facets.publishers).map(([publisher, count]) => (
                    <div key={publisher}>
                      <input
                        type="checkbox"
                        id={`publisher-${publisher}`}
                        checked={selectedFilters.publisher_name?.includes(publisher) || false}
                        onChange={() => handleFacetSelection('publisher_name', publisher)}
                      />
                      <label htmlFor={`publisher-${publisher}`}>
                        {publisher} ({count})
                      </label>
                    </div>
                  ))}

                  {/* Countries */}
                  <Typography variant="subtitle1">Countries</Typography>
                  {facets.countries && Object.entries(facets.countries).map(([country, count]) => (
                    <div key={country}>
                      <input
                        type="checkbox"
                        id={`country-${country}`}
                        checked={selectedFilters.primary_publisher_country?.includes(country) || false}
                        onChange={() => handleFacetSelection('primary_publisher_country', country)}
                      />
                      <label htmlFor={`country-${country}`}>
                        {country} ({count})
                      </label>
                    </div>
                  ))}

                </Paper>


              </YouTubeResultsWrapper>
            )}
          </>
        ) : ( 
        <Box className="main-content" display="flex">

        <SearchResultsWrapper>

          <Paper elevation={1} sx={{ p: 2, mt: 1, backgroundColor: '#FFCCCC' }}>


          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              whiteSpace: 'nowrap',
              paddingBottom: '2px',
              gap: '2px',
            }}
          >
        <Typography variant="subtitle1" sx={{ fontSize: '12px', color: 'darkblue' }}>
          <b>{`Showing ${calculateRange()} of ${totalResults} results`}</b>
        </Typography>


        <Box sx={{ position: 'relative', zIndex: 1000 }}> {/* Increase zIndex */}


        <IconButton onClick={toggleSortMenu}>
          <SortIcon />
        </IconButton>
        {showSortMenu && (
          <Box
            sx={{
              position: 'absolute',
              top: '40px', // Adjust to align directly below the icon
              left: '0', // Aligns the menu to the left edge of the icon
              zIndex: 1500, // Higher value to ensure visibility
              backgroundColor: '#fff',
              border: '1px solid #ddd',
              borderRadius: '4px',
              padding: '8px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <SortBy sortOrder={sortOrder} onSortChange={handleSortChange} />
          </Box>
        )}



            <IconButton onClick={() => handleDownload(filteredResults)}>
              <DownloadIcon />
            </IconButton>

            <IconButton onClick={() => setViewMode('list')}>
              <ListIcon />
            </IconButton>
            <IconButton onClick={() => setViewMode('grid')}>
              <GridIcon />
            </IconButton>

            <IconButton>
              <MoreVertIcon />
            </IconButton>
        </Box>
      </Box>
      
      </Paper>

      

      <Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />

      <Box className="main-content" display="flex">
      <SearchResults
        results={results}
        viewMode={viewMode}
        onTitleClick={(result) => window.open(result.detailLink, '_blank')}
      />
      </Box>

      <Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />

      </SearchResultsWrapper>
      

        {/* Right Sidebar */}
        <YouTubeResultsWrapper>

          {/* Databases Facets */}
          <Paper elevation={1} sx={{ p: 2, mt: 1, backgroundColor: '#FFCCCC' }}>
          <Typography variant="h6">Filter by:</Typography>

{/* Subjects */}
<Typography variant="subtitle1">Subjects</Typography>
{facets?.subjects && Object.entries(facets.subjects).length > 0 ? (
    Object.entries(facets.subjects).map(([subject, count]) => (
        <div key={subject}>
            <input
                type="checkbox"
                id={`subject-${subject}`}
                checked={selectedFilters.subjects_name_l3?.includes(subject) || false}
                onChange={() => handleFacetSelection('subjects_name_l3', subject)}
            />
            <label htmlFor={`subject-${subject}`}>
                {subject} ({count})
            </label>
        </div>
    ))
) : (
    <Typography variant="body2">No subjects available</Typography>
)}

<br></br>
{/* Authors */}
<Typography variant="subtitle1">Authors</Typography>
{facets.authors && Object.entries(facets.authors).map(([author, count]) => (
  <div key={author}>
    <input
      type="checkbox"
      id={`author-${author}`}
      checked={selectedFilters.authors?.includes(author) || false}
      onChange={() => handleFacetSelection('authors', author)}
    />
    <label htmlFor={`author-${author}`}>
      {author} ({count})
    </label>
  </div>
))}


<br></br>
{/* Journals */}
<Typography variant="subtitle1">Journals</Typography>
{facets.journals && Object.entries(facets.journals).map(([journal, count]) => (
  <div key={journal}>
    <input
      type="checkbox"
      id={`journal-${journal}`}
      checked={selectedFilters.journal_name?.includes(journal) || false}
      onChange={() => handleFacetSelection('journal_name', journal)}
    />
    <label htmlFor={`journal-${journal}`}>
      {journal} ({count})
    </label>
  </div>
))}





            <br></br>
            {/* Publishers */}
            <Typography variant="subtitle1">Publishers</Typography>
            {facets.publishers && Object.entries(facets.publishers).map(([publisher, count]) => (
              <div key={publisher}>
                <input
                  type="checkbox"
                  id={`publisher-${publisher}`}
                  checked={selectedFilters.publisher_name?.includes(publisher) || false}
                  onChange={() => handleFacetSelection('publisher_name', publisher)}
                />
                <label htmlFor={`publisher-${publisher}`}>
                  {publisher} ({count})
                </label>
              </div>
            ))}
            
            <br></br>
            {/* Countries */}
            <Typography variant="subtitle1">Publiscation Country</Typography>
            {facets.countries && Object.entries(facets.countries).map(([country, count]) => (
              <div key={country}>
                <input
                  type="checkbox"
                  id={`country-${country}`}
                  checked={selectedFilters.primary_publisher_country?.includes(country) || false}
                  onChange={() => handleFacetSelection('primary_publisher_country', country)}
                />
                <label htmlFor={`country-${country}`}>
                  {country} ({count})
                </label>
              </div>
            ))}


            </Paper>

          </YouTubeResultsWrapper>


        

         <LoginModal
          open={isLoginModalOpen}
          onClose={() => setIsLoginModalOpen(false)}
          onLoginSuccess={handleLoginSuccess}
        />

       <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="article-details-title"
          aria-describedby="article-details-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              right: '1%',
              transform: 'translate(0%, -50%)',
              width: '50%',
              bgcolor: '#f0fafa',
              boxShadow: 24,
              p: 1,
              overflow: 'auto',
              maxHeight: '100vh',
              [theme.breakpoints.down('md')]: {
                width: '90%', // Width adjustment for medium screens
              },
              [theme.breakpoints.down('sm')]: {
                width: '90%', // Width adjustment for small screens
                maxHeight: '70vh', // Adjust height for smaller screens
              },
            }}
          >
            {articleSource === 'eBooks' && (
              <ArticleDetailsDSpace
                selectedArticleId={selectedArticleId} // Pass selected article ID
                closeModal={() => setModalOpen(false)} // Function to close the modal
              />
            )}

          </Box>
        </Modal>
        </Box>
        )}
    </StyledContainer>
    </div>
  );
};

Journal_Articles.propTypes = {
  query: PropTypes.string.isRequired,
};

export default Journal_Articles;
