import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import SearchBox1 from '../components/SearchBox1';
import SearchResults from '../components/SearchResults';
import Pagination from '../components/Pagination';
import SortBy from '../components/SortBy';
import Facets from '../components/Facets';
import ListIcon from '@mui/icons-material/ViewList';
import GridIcon from '@mui/icons-material/ViewModule';
import LoginModal from '../components/LoginModal';
import OpenAIResponse from '../components/OpenAIResponse';
import fetchDSpaceResults from '../components/fetchDSpaceResults';
import defaultThumbnail from '../assets/logo4.png';
import { useTheme } from '@mui/material/styles';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';


import ArticleDetailsDSpace from '../pages/ArticleDetails_DSpace'; // Import the ArticleDetailsDSpace component


import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  Modal,
  Paper,
  IconButton, 
  Tabs,
  Tab,
  useMediaQuery,
} from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';



// Register the required components
ChartJS.register(ArcElement, Tooltip, Legend);  



// Function to convert search results to CSV format
const convertResultsToCSV = (results) => {
  const headers = ['Title', 'Abstract', 'Authors', 'Year', 'Source'];
  const rows = results.map(result => [
    `"${result.title}"`,
    `"${result.abstract}"`,
    `"${result.authors}"`,
    `"${result.year}"`,
    `"${result.source}"`,
  ]);

  const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
  return csvContent;
};

// Function to handle download
const handleDownload = (results) => {
  const csvData = convertResultsToCSV(results);
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'search_results.csv';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};



const EBooks = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryFromUrl = new URLSearchParams(location.search).get('query') || '';
  const filtersFromUrl = Object.fromEntries(
    new URLSearchParams(location.search).entries()
  );

  const isMobile = useMediaQuery('(max-width:768px)'); // Adjust breakpoint as needed
  const [tabValue, setTabValue] = useState(0);
  
  const [selectedDatabase, setSelectedDatabase] = useState(
    location.state?.selectedDatabase || 'All Databases'
  );

  const extractedFilters = {};
  Object.keys(filtersFromUrl).forEach(param => {
    if (param.startsWith('f.')) {
      const facetName = param.replace('f.', '');
      const value = filtersFromUrl[param].split(',')[0]; // Extract value
      extractedFilters[facetName] = value;
    }
  });
  
  const [query, setQuery] = useState(queryFromUrl);
  
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const handleDatabaseChange = (event) => {
    setSelectedDatabase(event.target.value);
  };

  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false); // State to control modal open/close
  const [selectedArticleId, setSelectedArticleId] = useState(null); // State to store selected article ID
  const [aiResponse, setAiResponse] = useState('');  // State for AI response
  const [shortenedResponse, setShortenedResponse] = useState('');  // State for shortened response
  const [isExpanded, setIsExpanded] = useState(false);  // State for toggling "Read More"
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [viewMode, setViewMode] = useState('list');
  const [sortOrder, setSortOrder] = useState('relevance');
  const [selectedSort, setSelectedSort] = useState({ field: 'score', direction: 'DESC' });

  // Add state for facets and selected filters
const [facets, setFacets] = useState({});
const [selectedFilters, setSelectedFilters] = useState({});

  const theme = useTheme(); // Access the theme

    // Check if user is logged in using localStorage
    const userLoggedIn = localStorage.getItem('token') ? true : false;
    
  const resultsPerPage = 20; // Define results per page


 
  const [showSortMenu, setShowSortMenu] = useState(false);

  const toggleSortMenu = () => {
    setShowSortMenu(prev => !prev);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  
  const truncateText = (text, maxLength) => {
    if (!text || text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

    // Example of `fetchResults` with properly closed braces and commas
    const fetchResults = useCallback(async (searchQuery, pageNumber = 1, filters = {}, sorting = selectedSort) => {
      setLoading(true);
      setError(null);
    
      try {
        // Define valid facets to filter only allowed filters
        const validFacets = ['itemtype', 'publisher', 'course', 'author'];
    
        // Filter out any invalid facets from selected filters
        const activeFilters = filters ? Object.keys(filters)
          .filter(facetName => validFacets.includes(facetName))
          .reduce((obj, key) => {
            obj[key] = filters[key];
            return obj;
          }, {}) : {};
    
        // Prepare filter parameters
        const filterParams = {};
        Object.keys(activeFilters).forEach(facetName => {
          const value = activeFilters[facetName];
          if (value) {
            filterParams[`f.${facetName}`] = `${value},equals`;
          }
        });
    
        // Fetch and format the results
        const response = await axios.get(`/api/server/api/discover/search/objects?query=${searchQuery}&embed=thumbnail&embed=item%2Fthumbnail`, {
          params: {
            sort: 'score,DESC',
            page: pageNumber - 1,
            size: resultsPerPage,
            scope: 'f86f89c4-00cc-4468-859f-8ca905c24475', // eBooks collection ID
            ...filterParams,
            sort: `${sorting.field},${sorting.direction}`, // Apply sorting parameter directly
          },
        });
    
        const searchResult = response.data._embedded?.searchResult || {};
        const dspaceDocs_TB = searchResult._embedded?.objects || [];
        const dspaceTotal_TB = searchResult.page?.totalElements || dspaceDocs_TB.length;
    
        const formattedDSpaceResults_TB = dspaceDocs_TB.map(result => {
          const metadata = result._embedded?.indexableObject?.metadata || {};
          const thumbnail = result._embedded?.indexableObject?._embedded?.thumbnail?._links?.content?.href ?? defaultThumbnail;
          return {
            title: metadata['dc.title']?.[0]?.value || 'No title available',
            abstract: truncateText(metadata['dc.description.abstract']?.[0]?.value || 'No abstract available', 200),
            authors: metadata['dc.contributor.author']?.[0]?.value || 'Unknown',
            year: metadata['dc.date.issued']?.[0]?.value || 'Unknown',
            type: metadata['dc.type']?.[0]?.value || 'Unknown',
            publisher: metadata['dc.publisher']?.[0]?.value || 'Unknown',
            course: metadata['dc.course']?.[0]?.value || 'Unknown',
            source: 'eBooks',
            detailLink: `/article/dspace/${result._embedded?.indexableObject.uuid}`,
            thumbnail,
          };
        });
    
        setResults(formattedDSpaceResults_TB); // Set the results for the current page
        setFilteredResults(formattedDSpaceResults_TB); // Display 20 results per page as per current page number
        setTotalResults(dspaceTotal_TB);
        setTotalPages(Math.ceil(dspaceTotal_TB / resultsPerPage)); // Update total pages based on the 20 results per page
      } catch (error) {
        console.error('Error fetching DSpace Textbooks data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    }, [query, page, selectedSort]);


  const fetchFacets = useCallback(async (searchQuery, filters) => {
    try {
      const filterParams = {};
      Object.keys(filters).forEach((facetName) => {
        const value = filters[facetName];
        if (value) {
          filterParams[`f.${facetName}`] = `${value},equals`;
        }
      });
  
      const response = await axios.get('/api/server/api/discover/search/facets', {
        params: {
          query: searchQuery,
          scope: 'f86f89c4-00cc-4468-859f-8ca905c24475',
          ...filterParams,
        },
      });
  
      const facetData = response.data._embedded.facets || [];
      // Format facets as per your structure
      const newFacets = {
        Course: {},
        Type: {},
        Publisher: {},
        Author: {},
      };
  
      // Populate newFacets from the API response
      facetData.forEach(facet => {
        if (facet.name === 'itemtype') {
          facet._embedded.values.forEach(value => {
            newFacets.Type[value.label] = value.count;
          });
        } else if (facet.name === 'publisher') {
          facet._embedded.values.forEach(value => {
            newFacets.Publisher[value.label] = value.count;
          });
        } else if (facet.name === 'course') {
          facet._embedded.values.forEach(value => {
            newFacets.Course[value.label] = value.count;
          });
        } else if (facet.name === 'author') {
          facet._embedded.values.forEach(value => {
            newFacets.Author[value.label] = value.count;
          });
        }
      });
  
      setFacets(newFacets); // Set the total count for facets
    } catch (error) {
      console.error('Error fetching facet data:', error);
    }
  }, []);



// Update onFacetChange to modify the URL with selected filters
const onFacetChange = (facetName, value) => {
  const newSelectedFilters = { ...selectedFilters, [facetName]: value };
  setSelectedFilters(newSelectedFilters); // Update filters first

  // Construct the URL with updated filters
  const searchParams = new URLSearchParams();
  searchParams.set('query', query);

  Object.keys(newSelectedFilters).forEach(key => {
    if (newSelectedFilters[key]) {
      // Use 'itemtype' for Type filter, and lowercase for other filters
      const filterKey = key === 'Type' ? 'itemtype' : key.toLowerCase();
      searchParams.delete(`f.${filterKey}`);
      searchParams.append(`f.${filterKey}`, `${newSelectedFilters[key]},`);
    }
  });

  // Update the URL with query parameters
  navigate(`/EBooks?${searchParams.toString()}`);

  // Fetch results with the updated filters
  fetchResults(query, page, newSelectedFilters);
  fetchFacets(query, newSelectedFilters);
};


      // Function to remove a filter
      const removeFilter = (facetName) => {
        const newFilters = { ...selectedFilters };
        delete newFilters[facetName];
        setSelectedFilters(newFilters);

        // Construct the URL with the updated filters
        const searchParams = new URLSearchParams();
        searchParams.set('query', query);

        Object.keys(newFilters).forEach(key => {
          if (newFilters[key]) {
            searchParams.append(`f.${key.toLowerCase()}`, `${newFilters[key]},`);
          }
        });

        // Update the URL
        navigate(`/EBooks?${searchParams.toString()}`);

        // Fetch results with the updated filters
        fetchResults(query, page, newFilters);
        fetchFacets(query, newFilters);
      };
 
      // JSX to render filter tags
      const FilterTags = () => (
        <div className="filter-tags">
          {Object.keys(selectedFilters).map((facetName) => (
            <span key={facetName} className="filter-tag">
              {facetName}: {selectedFilters[facetName]}{" "}
              <button onClick={() => removeFilter(facetName)}>&times;</button>
            </span>
          ))}
        </div>
      );

      const calculateRange = () => {
        const startIndex = (page - 1) * resultsPerPage + 1;
        const endIndex = Math.min(page * resultsPerPage, totalResults);
        return `${startIndex}-${endIndex}`;
      };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  
    const searchParams = new URLSearchParams();
    searchParams.set('query', query);
    Object.keys(selectedFilters).forEach((key) => {
      searchParams.append(`f.${key}`, `${selectedFilters[key]},`);
    });
    searchParams.set('spc.page', newPage);
  
    navigate(`/EBooks?${searchParams.toString()}`);
  
    fetchResults(query, newPage, selectedFilters);
  };

  
// Refetch results when query or page changes
useEffect(() => {
  const queryFromUrl = new URLSearchParams(location.search).get('query') || '';
  const filtersFromUrl = Object.fromEntries(new URLSearchParams(location.search).entries());

  const extractedFilters = {};
  Object.keys(filtersFromUrl).forEach((param) => {
    if (param.startsWith('f.')) {
      const facetName = param.replace('f.', '');
      const value = filtersFromUrl[param].split(',')[0]; // Extract value
      extractedFilters[facetName] = value;
    }
  });

  const pageFromUrl = parseInt(new URLSearchParams(location.search).get('spc.page')) || 1;

  setQuery(queryFromUrl);
  setSelectedFilters(extractedFilters);
  setPage(pageFromUrl);

  fetchResults(queryFromUrl, pageFromUrl, extractedFilters);
  fetchFacets(queryFromUrl, extractedFilters);
}, [location.search]);


  

const handleSortChange = (newSortOrder) => {
  setSortOrder(newSortOrder);

  let sortField = 'score';
  let sortDirection = 'DESC';

  if (newSortOrder === 'alphabetical') {
    sortField = 'dc.title';
    sortDirection = 'ASC';
  } else if (newSortOrder === 'latest') {
    sortField = 'dc.date.issued';
    sortDirection = 'DESC';
  }

  setSelectedSort({ field: sortField, direction: sortDirection });



  fetchResults(query, page, selectedFilters, { field: sortField, direction: sortDirection });
};

  


  const StyledContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(4),
  }));


  const SearchResultsWrapper = styled(Box)(({ theme }) => ({
    width: '70%', // Default for larger screens
    paddingLeft: theme.spacing(2),
    minHeight: '500px',
    [theme.breakpoints.down('md')]: {
      width: '100%', // Full width on medium screens or smaller
      paddingLeft: 0,
    },
  }));

  const YouTubeResultsWrapper = styled(Box)(({ theme }) => ({
    width: '30%', // Default for larger screens
    paddingLeft: theme.spacing(2),
    minHeight: '500px',
    [theme.breakpoints.down('md')]: {
      width: '100%', // Full width on medium screens or smaller
      paddingLeft: 0,
    },
  }));


  // Function to handle title click
  const onTitleClick = (result) => {
    if (!userLoggedIn) {
      localStorage.setItem('redirectAfterLogin', JSON.stringify(result)); // Store clicked article details
      setIsLoginModalOpen(true);
      return;
    }
  
    if (result.source === 'Journal Articles') {
      const id = result.detailLink;
      window.open(id, '_blank'); // Open the link in a new browser tab
    } else if (['eBooks', 'Leisure Readings'].includes(result.source)) {
      const id = result.detailLink.split('/').pop();
      setSelectedArticleId(id);
      setArticleSource('eBooks'); // Set the source type
      setModalOpen(true);
    }
  };


  const handleLoginSuccess = () => {
    setIsLoginModalOpen(false);
    localStorage.setItem('token', 'user-token'); // Save token (mocking login success)
    
    const redirectInfo = JSON.parse(localStorage.getItem('redirectAfterLogin'));
    if (redirectInfo) {
      localStorage.removeItem('redirectAfterLogin'); // Clear the stored redirect info
  
      // Redirect to the stored action
      if (redirectInfo.source === 'eJournal Articles') {
        window.open(redirectInfo.detailLink, '_blank');
      } else if (['eBooks', 'Leisure Readings'].includes(redirectInfo.source)) {
        const id = redirectInfo.detailLink.split('/').pop();
        setSelectedArticleId(id);
        setArticleSource('eBooks');
        setModalOpen(true);
      }
    } else {
      window.location.reload(); // Reload to refresh state
    }
  };



const [articleSource, setArticleSource] = useState(''); // Track article source type



  return (
    <div style={{ backgroundColor: '#FFE6E6', minHeight: '100vh', minWidth: '100vh' }} className="flex flex-col">
    <StyledContainer>
    
    <SearchBox1
      onSearch={(searchQuery) => {
        const sanitizedQuery = (searchQuery || '').toString().trim();
        setQuery(sanitizedQuery);

        const searchParams = new URLSearchParams();
        searchParams.set('query', sanitizedQuery);


          // Conditional navigation based on database selection
          if (selectedDatabase === 'eBooks') {
            navigate(`/EBooks?query=${encodeURIComponent(sanitizedQuery)}`);
          } else if (selectedDatabase === 'eJournals') {
            navigate(`/EJournals?query=${encodeURIComponent(sanitizedQuery)}`);
          } else if (selectedDatabase === 'Journal Articles') {
            navigate(`/Journal_Articles?query=${encodeURIComponent(sanitizedQuery)}`);
          } else {
            navigate(`/single-search?query=${encodeURIComponent(sanitizedQuery)}`);
          }

        // Fetch results with the new query
        fetchResults(sanitizedQuery, 1, selectedFilters);
        fetchFacets(sanitizedQuery, selectedFilters);
      }}
      initialQuery={query || ''}
      selectedDatabase="eBooks"  // Set default selection
    />
<br></br>


    {loading && <CircularProgress />}
      {error && (
        <Snackbar open autoHideDuration={6000}>
          <div>Error fetching results. Please try again.</div>
        </Snackbar>
      )}      

{isMobile ? (
          <>
            {/* Tabs for mobile view */}
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="Search Results" />
              <Tab label="Filter Results" />
            </Tabs>

            {tabValue === 0 && (
              <SearchResultsWrapper>
                <SearchResults
                  results={filteredResults}
                  viewMode={viewMode}
                  onTitleClick={onTitleClick}
                />
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </SearchResultsWrapper>
            )}
            {tabValue === 1 && (
              <YouTubeResultsWrapper>
          {/* Databases Facets */}
          <Paper elevation={1} sx={{ p: 2, mt: 1, backgroundColor: '#FFCCCC' }}>
              <Facets
                facets={facets}
                selectedFacets={selectedFilters}
                onFacetChange={onFacetChange}
              />

                </Paper>

                <OpenAIResponse query={query} />
              </YouTubeResultsWrapper>
            )}
          </>
        ) : ( 
        <Box className="main-content" display="flex">

        <SearchResultsWrapper>

          <Paper elevation={1} sx={{ p: 2, mt: 1, backgroundColor: '#FFCCCC' }}>


          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              whiteSpace: 'nowrap',
              paddingBottom: '2px',
              gap: '2px',
            }}
          >
        <Typography variant="subtitle1" sx={{ fontSize: '12px', color: 'darkblue' }}>
          <b>{`Showing ${calculateRange()} of ${totalResults} results`}</b>
        </Typography>


        <Box sx={{ position: 'relative', zIndex: 1000 }}> {/* Increase zIndex */}


        <IconButton onClick={toggleSortMenu}>
          <SortIcon />
        </IconButton>

        {showSortMenu && (
          <Box
            sx={{
              position: 'absolute',
              top: '40px', // Adjust to align directly below the icon
              left: '0', // Aligns the menu to the left edge of the icon
              zIndex: 1500, // Higher value to ensure visibility
              backgroundColor: '#fff',
              border: '1px solid #ddd',
              borderRadius: '4px',
              padding: '8px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <SortBy sortOrder={sortOrder} onSortChange={handleSortChange} />
          </Box>
        )}
            <IconButton onClick={() => handleDownload(filteredResults)}>
              <DownloadIcon />
            </IconButton>

            <IconButton onClick={() => setViewMode('list')}>
              <ListIcon />
            </IconButton>
            <IconButton onClick={() => setViewMode('grid')}>
              <GridIcon />
            </IconButton>

            <IconButton>
              <MoreVertIcon />
            </IconButton>
        </Box>
      </Box>
      
      </Paper>

      <Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />
        <FilterTags />

      <Box className="main-content" display="flex">
      <SearchResults 
        results={results} 
        viewMode={viewMode} 
        onTitleClick={onTitleClick} 
      />
      </Box>

      <Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />

      </SearchResultsWrapper>
      

        {/* Right Sidebar */}
        <YouTubeResultsWrapper>

             

          {/* Databases Facets */}
          <Paper elevation={1} sx={{ p: 2, mt: 1, backgroundColor: '#FFCCCC' }}>
          <Facets
            facets={facets}
            selectedFacets={selectedFilters}
            onFacetChange={onFacetChange}
          />

            </Paper>

            <OpenAIResponse query={query} />

          </YouTubeResultsWrapper>


        

         <LoginModal
          open={isLoginModalOpen}
          onClose={() => setIsLoginModalOpen(false)}
          onLoginSuccess={handleLoginSuccess}
        />

       <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="article-details-title"
          aria-describedby="article-details-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              right: '1%',
              transform: 'translate(0%, -50%)',
              width: '50%',
              bgcolor: '#f0fafa',
              boxShadow: 24,
              p: 1,
              overflow: 'auto',
              maxHeight: '100vh',
              [theme.breakpoints.down('md')]: {
                width: '90%', // Width adjustment for medium screens
              },
              [theme.breakpoints.down('sm')]: {
                width: '90%', // Width adjustment for small screens
                maxHeight: '70vh', // Adjust height for smaller screens
              },
            }}
          >
            {articleSource === 'eBooks' && (
              <ArticleDetailsDSpace
                selectedArticleId={selectedArticleId} // Pass selected article ID
                closeModal={() => setModalOpen(false)} // Function to close the modal
              />
            )}

          </Box>
        </Modal>
        </Box>
        )}
    </StyledContainer>
    </div>
  );
};

EBooks.propTypes = {
  query: PropTypes.string.isRequired,
};

export default EBooks;
