import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, Card, CardContent, Grid } from '@mui/material';
import './SearchResults.css';
import defaultThumbnail from '../assets/logo4.png';

const stripHTML = (text) => {
  if (!text) return '';
  return text.replace(/<[^>]+>/g, '');
};

const highlightText = (text, keyword) => {
  if (!keyword || !text) return text;

  // Escape special regex characters in the keyword to avoid breaking the regex pattern.
  const escapedKeyword = keyword.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  const regex = new RegExp(`(${escapedKeyword})`, 'gi');

  // Split text by the keyword regex and wrap matches in a span with the "highlight" class.
  return text.split(regex).map((part, index) =>
    part.toLowerCase() === keyword.toLowerCase() ? (
      <span key={index} className="highlight">
        {part}
      </span>
    ) : (
      part
    )
  );
};

const SearchResults = ({ results, viewMode, onTitleClick, searchQuery }) => {
  const formatAuthors = (authors) => {
    if (!authors) return '';
    return Array.isArray(authors) ? authors.join(' | ') : authors.replace(/,/g, ' | ');
  };

  return (
    <Paper sx={{ width: '100%', minHeight: '300px', backgroundColor: '#f9fcfc' }}>
      {viewMode === 'list' ? (
        results.map((result, index) => (
          <Card key={index}>
            <CardContent sx={{ backgroundColor: '#FFF2F2' }}>
              <Typography
                sx={{ fontSize: '14px', cursor: 'pointer', color: 'darkblue', textDecoration: 'none' }}
                onClick={() => onTitleClick(result)}
              >
                <b>{highlightText(result.title, searchQuery)}</b>
              </Typography>
              <Typography
                sx={{ fontSize: '14px', color: '#000000' }} // Normal color, no pointer
              >
                {formatAuthors(result.authors)}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                {highlightText(stripHTML(result.abstract), searchQuery)}
              </Typography>
              <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    textOverflow: 'ellipsis'
                  }}
                >
                  <b>{highlightText(result.journal_name || 'No Journal Name', searchQuery)}</b>,&nbsp;{highlightText(result.year || 'Unknown Year', searchQuery)},&nbsp;
                  <b>DOI: {highlightText(result.articledoi || 'No Journal Name', searchQuery)}</b>,&nbsp;Publisher: {highlightText(result.publisher_name || 'No Publisher Name', searchQuery)}
                </Typography>
                <Typography sx={{ fontSize: '14px', color: '#000000' }} >
                <b>Subject Areas:</b> {formatAuthors(result.subjects_name_l3)}
              </Typography>
            </CardContent>
          </Card>
        ))
      ) : (
        <Grid container spacing={2}>
          {results.map((result, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card>
                <CardContent>
                  <Typography
                    sx={{ fontSize: '14px', cursor: 'pointer', color: 'darkblue', textDecoration: 'none' }}
                    onClick={() => onTitleClick(result)}
                  >
                    <b>{highlightText(result.title, searchQuery)}</b>
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {highlightText(stripHTML(result.abstract), searchQuery)}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {highlightText(result.journal_name, searchQuery)}, {highlightText(result.year, searchQuery)},
                  </Typography>
                  <Typography
                    variant="body2" color="text.secondary"
                    sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                  >
                    <b>{highlightText(result.articledoi, searchQuery)}</b>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Paper>
  );
};

SearchResults.propTypes = {
  results: PropTypes.array.isRequired,
  viewMode: PropTypes.string.isRequired,
  searchQuery: PropTypes.string
};

export default SearchResults;
