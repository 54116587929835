import axios from 'axios';
import defaultThumbnail from '../assets/logo4.png'; // Adjust path as needed

const API_URL = '/api/server/api/discover/search/objects' || 'https://idr.informaticsglobal.ai/api/server/api/discover/search/objects';

const fetchDSpaceResults = async (query, page, sorting, source, resultsPerPage) => {


    const scopeIds = [
      { name: 'eBooks', scope: 'f86f89c4-00cc-4468-859f-8ca905c24475' },
    ];

    let combinedResults = [];
    let totalResultsCount = 0;

  try {
    for (const scopeId of scopeIds) {
    const response = await axios.get(`${API_URL}?query=${query}&embed=thumbnail&embed=item%2Fthumbnail`, {
      params: {
        page: page - 1,
        size: resultsPerPage,
        sort: `${sorting.field},${sorting.direction}`,
        scope: scopeId.scope,
        size: 10, // Limit results per page
      },
    });

    // Handle response
    const searchResult = response.data._embedded?.searchResult || {};
    const docs = searchResult._embedded?.objects || [];
    const total = searchResult.page?.totalElements || docs.length;

    // Format results
    const formattedResults = docs.map(result => {
      const metadata = result._embedded?.indexableObject?.metadata || {};
      const thumbnail = result._embedded?.indexableObject?._embedded?.thumbnail?._links?.content?.href ?? defaultThumbnail;

      return {
        title: metadata['dc.title']?.[0]?.value || 'No title available',
        abstract: metadata['dc.description.abstract']?.[0]?.value || 'No abstract available',
        authors: metadata['dc.contributor.author']?.[0]?.value || 'Unknown',
        year: metadata['dc.date.issued']?.[0]?.value || 'Unknown',
        type: metadata['dc.type']?.[0]?.value || 'Unknown',
        publisher: metadata['dc.publisher']?.[0]?.value || 'Unknown',
        detailLink: `/article/dspace/${result._embedded?.indexableObject.uuid}`,
        source: 'eBooks',
        thumbnail,
      };
    });

    combinedResults = [...combinedResults, ...formattedResults]; // Aggregate results
    totalResultsCount += total; // Add to total count
  }
  return { results: combinedResults, total: totalResultsCount };
  } catch (error) {
    console.error(`Error fetching DSpace results for ${source}:`, error.response?.data || error.message);
    return { results: [], total: 0 };
  }
};

export default fetchDSpaceResults;
