import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import SearchBox1 from '../components/SearchBox1';
import SearchResults from '../components/SearchResults';
import Pagination from '../components/Pagination';
import SortBy from '../components/SortBy';
import Facets from '../components/Facets';
import ListIcon from '@mui/icons-material/ViewList';
import GridIcon from '@mui/icons-material/ViewModule';
import LoginModal from '../components/LoginModal';
import OpenAIResponse from '../components/OpenAIResponse';
import fetchDSpaceResults from '../components/fetchDSpaceResults';
import fetchDSpaceResults_Leisure from '../components/fetchDSpaceResults_Leisure';
import fetchJGateResults from '../components/fetchJGateResults';
import defaultThumbnail from '../assets/logo4.png';
import { useTheme } from '@mui/material/styles';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

import { Doughnut } from 'react-chartjs-2';
import ArticleDetailsDSpace from '../pages/ArticleDetails_DSpace'; // Import the ArticleDetailsDSpace component


import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  Modal,
  Paper,
  IconButton, 
  Tabs,
  Tab,
  useMediaQuery,
} from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';



// Register the required components
ChartJS.register(ArcElement, Tooltip, Legend);  

// Doughnut chart data function
const getDoughnutChartData = (facets) => {
  if (!facets || !facets.Databases) return { labels: [], datasets: [] };

  const labels = Object.keys(facets.Databases).filter(
    label => label !== 'All Databases'
  );
  const data = labels.map(label => facets.Databases[label]);

  return {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: ['#3f51b5', '#4caf50', '#ff9800', '#f44336', '#9c27b0', '#2196f3'],
        hoverBackgroundColor: ['#303f9f', '#388e3c', '#fb8c00', '#d32f2f', '#7b1fa2', '#1976d2'],
      },
    ],
  };
};


// Function to convert search results to CSV format
const convertResultsToCSV = (results) => {
  const headers = ['Title', 'Abstract', 'Authors', 'Year', 'Source'];
  const rows = results.map(result => [
    `"${result.title}"`,
    `"${result.abstract}"`,
    `"${result.authors}"`,
    `"${result.year}"`,
    `"${result.source}"`,
  ]);

  const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
  return csvContent;
};

// Function to handle download
const handleDownload = (results) => {
  const csvData = convertResultsToCSV(results);
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'search_results.csv';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};






const SingleSearch = () => {
  const location = useLocation();
  
  const navigate = useNavigate();

  const isMobile = useMediaQuery('(max-width:768px)'); // Adjust breakpoint as needed
  const [tabValue, setTabValue] = useState(0);
  

  const [searchParams] = useSearchParams();
  const initialQuery = searchParams.get('query') || '';
  const [query, setQuery] = useState(initialQuery);


  const [selectedDatabase, setSelectedDatabase] = useState(
    location.state?.selectedDatabase || 'All Databases'
  );

  const handleDatabaseChange = (event) => {
    setSelectedDatabase(event.target.value);
  };

  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false); // State to control modal open/close
  const [selectedArticleId, setSelectedArticleId] = useState(null); // State to store selected article ID
  const [aiResponse, setAiResponse] = useState('');  // State for AI response
  const [shortenedResponse, setShortenedResponse] = useState('');  // State for shortened response
  const [isExpanded, setIsExpanded] = useState(false);  // State for toggling "Read More"
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [viewMode, setViewMode] = useState('list');
  const [sortOrder, setSortOrder] = useState('relevance');
  const [selectedSort, setSelectedSort] = useState({ field: 'score', direction: 'DESC' });

  const theme = useTheme(); // Access the theme

    // Check if user is logged in using localStorage
    const userLoggedIn = localStorage.getItem('token') ? true : false;
    
  const [facets, setFacets] = useState({
    Databases: {},
  });

  const [selectedFilters, setSelectedFilters] = useState({
    Databases: 'All Databases',
  });
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  

  const resultsPerPage = 20; // Define results per page


 
  const [showSortMenu, setShowSortMenu] = useState(false);

  const toggleSortMenu = () => {
    setShowSortMenu(prev => !prev);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const OPENAI_API_KEY = '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'; // Replace with your OpenAI API key

  
  const [semanticTerms, setSemanticTerms] = useState([]); // Initialize as an array
  //const [semanticTerms, setSemanticTerms] = useState('India, South Asia, Hinduism, Bollywood, Indian cuisine');


  const processQueryWithOpenAI = async (searchQuery) => {
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'system',
              content: `You are a library assistant. Extract relevant semantic search terms from user messages to help find books. Include synonyms, related concepts, and alternate phrasing where applicable.`,
            },
            { role: 'user', content: searchQuery },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      const searchTerms = response.data.choices[0]?.message?.content?.trim();
      const termsArray = searchTerms
      ? searchTerms.split(/[\n,]+/).map(term => term.trim()).filter(Boolean)
      : [];

      return termsArray;
    } catch (error) {
      console.error('Error processing query with OpenAI:', error.message);
      return [];
    }
  };

  
  

  const fetchResults = useCallback(async (searchQuery, pageNumber = 1, filters = {}, sorting = selectedSort) => {
    setLoading(true);
    setError(null);
  
    let dspaceTotal = 0;
    let dspaceTotal_Leisure = 0;
    let jgateTotal = 0;
    let combinedResults = [];
    let combinedQuery = '';
    let extractedTerms = [];
  
    try {
    // Step 1: Extract semantic terms only if `semanticTerms` is empty
    if (semanticTerms.length === 0) {

      const terms = await processQueryWithOpenAI(searchQuery);
      if (terms) {
        extractedTerms = terms.slice(0, 5); // Limit to 5 terms
        setSemanticTerms(extractedTerms); // Update state with semantic terms
        combinedQuery = extractedTerms.join(' OR ');
      }
    } else {
      combinedQuery = semanticTerms.join(' OR ');
    }


  
  
      // Step 2: Fetch DSpace Results (Cyber Security Collection)
      if (!filters.Databases || filters.Databases === 'All Databases' || filters.Databases === 'eBooks') {
        const dspaceResults = await fetchDSpaceResults(combinedQuery, pageNumber, sorting, resultsPerPage);
        dspaceTotal = dspaceResults.total || 0; // Total from DSpace API
        combinedResults = [...combinedResults, ...dspaceResults.results];
      }
  
      // Step 3: Fetch JGate Results if applicable
      if (!filters.Databases || filters.Databases === 'All Databases' || filters.Databases === 'Journal Articles') {
        const jgateResults = await fetchJGateResults(combinedQuery, pageNumber, sorting, resultsPerPage);
        console.log(`JGate Results for Journal Articles:`, jgateResults);
        jgateTotal = jgateResults.total || 0; // Total from JGate API
        combinedResults = [...combinedResults, ...jgateResults.results];
      }
  
      // Step 4: Fetch DSpace Results (Leisure Collection)
      if (!filters.Databases || filters.Databases === 'All Databases' || filters.Databases === 'Leisure Readings') {
        const dspaceResults_Leisure = await fetchDSpaceResults_Leisure(combinedQuery, pageNumber, sorting, resultsPerPage);
        dspaceTotal_Leisure = dspaceResults_Leisure.total || 0; // Total from DSpace API
        combinedResults = [...combinedResults, ...dspaceResults_Leisure.results];
      }
  
      // Step 5: Calculate total results count
      const totalResultsCount = dspaceTotal + dspaceTotal_Leisure + jgateTotal;
  
      // Update facets
      const calculatedFacets = {
        Databases: {
          "All Databases": totalResultsCount,
          "eBooks": dspaceTotal,
          "Journal Articles": jgateTotal,
          "Leisure Readings": dspaceTotal_Leisure,
        },
      };
      setFacets(calculatedFacets);
  
      // Update results and total
      const filtered = filterResults(combinedResults);
      setResults(combinedResults);
      setFilteredResults(filtered);
      setTotalResults(totalResultsCount || 0); // Use totalResultsCount
      setTotalPages(Math.ceil(totalResultsCount / resultsPerPage)); // Update pagination
    } catch (error) {
      console.error('Error fetching results:', error.message);
      setError('Failed to fetch results.');
    }
  
    setLoading(false);
  }, [semanticTerms, selectedFilters, resultsPerPage, selectedSort]);



  


  const filterResults = (results) => {
    if (!results || selectedFilters.Databases === 'All Databases') {
      return results; // Return all results if no specific filter is selected
    }
  
    // Filter results based on the selected database filter
    return results.filter(result => {
      if (selectedFilters.Databases === 'eBooks') {
        return result.source === 'eBooks';
      }
      if (selectedFilters.Databases === 'Leisure Readings') {
        return result.source === 'Leisure Readings';
      }
      if (selectedFilters.Databases === 'Journal Articles') {
        return result.source === 'Journal Articles';
      }
      return false; // No match, exclude the result
    });
  };
  

  const handleRemoveSemanticTerm = (termToRemove) => {
    const updatedTerms = semanticTerms.filter(term => term !== termToRemove);
    setSemanticTerms(updatedTerms);
  
    const combinedQuery = semanticTerms.join(' OR '); // Ensures terms are separate for query
    fetchResults(combinedQuery, 1, selectedFilters, selectedSort);
  };


  const calculateRange = () => {
    const startIndex = (page - 1) * resultsPerPage + 1;
    const endIndex = Math.min(page * resultsPerPage, totalResults); // Use totalResults for the upper bound
    return totalResults > 0 ? `${startIndex}-${endIndex}` : '0'; // Ensure it doesn't show "1-0"
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  
    const combinedQuery = semanticTerms.length > 0
      ? semanticTerms.join(' OR ')
      : query;
  
    fetchResults(combinedQuery, newPage, selectedFilters, selectedSort);
  };

  
// Refetch results when query or page changes
useEffect(() => {
  if (query) {
    fetchResults(query, page, selectedFilters);
  }
}, [fetchResults, query, page, selectedFilters, selectedDatabase]);

 



  

  const handleSortChange = (newSortOrder) => {
    setSortOrder(newSortOrder);
    
    let sortField = 'score';
    let sortDirection = 'DESC';
  
    if (newSortOrder === 'alphabetical') {
      sortField = 'dc.title';
      sortDirection = 'ASC';
    } else if (newSortOrder === 'latest') {
      sortField = 'dc.date.issued';
      sortDirection = 'DESC';
    }
  
    // Update sorting state
    setSelectedSort({ field: sortField, direction: sortDirection });
  
    // Update the URL for sorting

    searchParams.set('query', query);
    searchParams.set('sort', `${sortField},${sortDirection}`);
    navigate(`/single-search?${searchParams.toString()}`);
  
    // Fetch results with new sorting
    fetchResults(query, page, selectedFilters, { field: sortField, direction: sortDirection });
  };

  


  const StyledContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(4),
  }));


  const SearchResultsWrapper = styled(Box)(({ theme }) => ({
    width: '70%', // Default for larger screens
    paddingLeft: theme.spacing(2),
    minHeight: '500px',
    [theme.breakpoints.down('md')]: {
      width: '100%', // Full width on medium screens or smaller
      paddingLeft: 0,
    },
  }));

  const YouTubeResultsWrapper = styled(Box)(({ theme }) => ({
    width: '30%', // Default for larger screens
    paddingLeft: theme.spacing(2),
    minHeight: '500px',
    [theme.breakpoints.down('md')]: {
      width: '100%', // Full width on medium screens or smaller
      paddingLeft: 0,
    },
  }));


  // Function to handle title click
  const onTitleClick = (result) => {
    if (!userLoggedIn) {
      localStorage.setItem('redirectAfterLogin', JSON.stringify(result)); // Store clicked article details
      setIsLoginModalOpen(true);
      return;
    }
  
    if (result.source === 'Journal Articles') {
      const id = result.detailLink;
      window.open(id, '_blank'); // Open the link in a new browser tab
    } else if (['eBooks', 'Leisure Readings'].includes(result.source)) {
      const id = result.detailLink.split('/').pop();
      setSelectedArticleId(id);
      setArticleSource('eBooks'); // Set the source type
      setModalOpen(true);
    }
  };


  const handleLoginSuccess = () => {
    setIsLoginModalOpen(false);
    localStorage.setItem('token', 'user-token'); // Save token (mocking login success)
    
    const redirectInfo = JSON.parse(localStorage.getItem('redirectAfterLogin'));
    if (redirectInfo) {
      localStorage.removeItem('redirectAfterLogin'); // Clear the stored redirect info
  
      // Redirect to the stored action
      if (redirectInfo.source === 'eJournal Articles') {
        window.open(redirectInfo.detailLink, '_blank');
      } else if (['eBooks', 'Leisure Readings'].includes(redirectInfo.source)) {
        const id = redirectInfo.detailLink.split('/').pop();
        setSelectedArticleId(id);
        setArticleSource('eBooks');
        setModalOpen(true);
      }
    } else {
      window.location.reload(); // Reload to refresh state
    }
  };


  const handleDoughnutClick = (event, elements) => {
    if (!elements.length) return; // No element clicked
  
    const chartIndex = elements[0].index; // Get the index of the clicked segment
    const labels = getDoughnutChartData(facets).labels; // Get labels from the chart data
    const selectedLabel = labels[chartIndex]; // Get the label for the clicked segment
  
    if (selectedLabel) {
      const newSelectedFilters = { ...selectedFilters, Databases: selectedLabel };
      setSelectedFilters(newSelectedFilters); // Update selected filters
      setPage(1); // Reset to the first page
      fetchResults(query, 1, newSelectedFilters); // Fetch results with the updated filter
    }
  };




const [articleSource, setArticleSource] = useState(''); // Track article source type


  return (
    <div style={{ backgroundColor: '#FFE6E6', minHeight: '100vh', minWidth: '100vh' }} className="flex flex-col">
    <StyledContainer>
    
    <SearchBox1
      onSearch={(searchQuery, selectedDatabase) => {
        const sanitizedQuery = (searchQuery || '').toString().trim();
        setQuery(sanitizedQuery);
        
          // Conditional navigation based on database selection
        if (selectedDatabase === 'eBooks') {
          navigate(`/EBooks?query=${encodeURIComponent(sanitizedQuery)}`);
        } else if (selectedDatabase === 'eJournals') {
          navigate(`/EJournals?query=${encodeURIComponent(sanitizedQuery)}`);
        } else if (selectedDatabase === 'Journal Articles') {
          navigate(`/Journal_Articles?query=${encodeURIComponent(sanitizedQuery)}`);
        } else {
          navigate(`/single-search?query=${encodeURIComponent(sanitizedQuery)}`);
        }

        setSemanticTerms([]); // Clear existing terms
        fetchResults(sanitizedQuery, 1, selectedDatabase);
      }}
      initialQuery={query || ''}
    />
<br></br>
    {`AI based Semantic Keyword suggestions:`}
    <div style={{ display: 'flex', flexWrap: 'wrap', margin: '10px 0' }}>
    {semanticTerms.map((term, index) => (
      <div
        key={index}
        style={{
          backgroundColor: '#4e5a65',
          color: '#fff',
          padding: '5px 10px',
          margin: '5px',
          borderRadius: '15px',
          cursor: 'pointer',
          fontSize: 12,
        }}
        onClick={() => handleRemoveSemanticTerm(term)}
      >
        {term.replace(/^-/, '')} ✖
      </div>
    ))}
    </div>

    {loading && <CircularProgress />}
      {error && (
        <Snackbar open autoHideDuration={6000}>
          <div>Error fetching results. Please try again.</div>
        </Snackbar>
      )}      

{isMobile ? (
          <>
            {/* Tabs for mobile view */}
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="Search Results" />
              <Tab label="Filter Results" />
            </Tabs>

            {tabValue === 0 && (
              <SearchResultsWrapper>
                <SearchResults
                  results={filteredResults}
                  viewMode={viewMode}
                  onTitleClick={onTitleClick}
                />
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </SearchResultsWrapper>
            )}
            {tabValue === 1 && (
              <YouTubeResultsWrapper>
              <Paper elevation={1} sx={{ p: 2, mt: 1, backgroundColor: '#FFCCCC' }}>
              <Facets
                facets={facets}
                selectedFacets={selectedFilters}
                onFacetChange={(facetName, value) => {
                  const newSelectedFilters = { ...selectedFilters, [facetName]: value };
                  setSelectedFilters(newSelectedFilters);
                  setPage(1); // Reset to the first page
                  fetchResults(query, 1, newSelectedFilters); // Fetch results with the selected filter
                }}
              />


                {/* Doughnut Chart for Databases Facets */}

                <Doughnut
                  data={getDoughnutChartData(facets)}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    cutout: '50%', // Adjust to make the doughnut circle larger
                    plugins: {
                      legend: {
                        display: false, // Disable the legend
                      },
                    },
                    onClick: (event, elements) => handleDoughnutClick(event, elements),
                  }}
                  style={{ maxHeight: '200px', maxWidth: '200px' }} // Increase size as needed
                />
                </Paper>

               <OpenAIResponse query={query} />
              </YouTubeResultsWrapper>
            )}
          </>
        ) : ( 
        <Box className="main-content" display="flex">

        <SearchResultsWrapper>

          <Paper elevation={1} sx={{ p: 2, mt: 1, backgroundColor: '#FFCCCC' }}>


          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              whiteSpace: 'nowrap',
              paddingBottom: '2px',
              gap: '2px',
            }}
          >
        <Typography variant="subtitle1" sx={{ fontSize: '12px', color: 'darkblue' }}>
          <b>{`Showing ${calculateRange()} of ${totalResults} results`}</b>
        </Typography>


        <Box sx={{ position: 'relative', zIndex: 1000 }}> {/* Increase zIndex */}


        <IconButton onClick={toggleSortMenu}>
          <SortIcon />
        </IconButton>

        {showSortMenu && (
          <Box
            sx={{
              position: 'absolute',
              top: '40px', // Adjust to align directly below the icon
              left: '0', // Aligns the menu to the left edge of the icon
              zIndex: 1500, // Higher value to ensure visibility
              backgroundColor: '#fff',
              border: '1px solid #ddd',
              borderRadius: '4px',
              padding: '8px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <SortBy sortOrder={sortOrder} onSortChange={handleSortChange} />
          </Box>
        )}
            <IconButton onClick={() => handleDownload(filteredResults)}>
              <DownloadIcon />
            </IconButton>

            <IconButton onClick={() => setViewMode('list')}>
              <ListIcon />
            </IconButton>
            <IconButton onClick={() => setViewMode('grid')}>
              <GridIcon />
            </IconButton>

            <IconButton>
              <MoreVertIcon />
            </IconButton>
        </Box>
      </Box>
      
      </Paper>

      <Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />

      <Box className="main-content" display="flex">
      <SearchResults 
      results={filteredResults} 
      viewMode={viewMode} 
      onTitleClick={onTitleClick} 
      />
      </Box>

      <Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />

      </SearchResultsWrapper>
      

        {/* Right Sidebar */}
        <YouTubeResultsWrapper>

          {/* Databases Facets */}
          <Paper elevation={1} sx={{ p: 2, mt: 1, backgroundColor: '#FFCCCC' }}>
          <Facets
            facets={facets}
            selectedFacets={selectedFilters}
            onFacetChange={(facetName, value) => {
              const newSelectedFilters = { ...selectedFilters, [facetName]: value };
              setSelectedFilters(newSelectedFilters);
              setPage(1); // Reset to the first page
              fetchResults(query, 1, newSelectedFilters); // Fetch results with the selected filter
            }}
          />


            {/* Doughnut Chart for Databases Facets */}

            <Doughnut
              data={getDoughnutChartData(facets)}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                cutout: '50%', // Adjust to make the doughnut circle larger
                plugins: {
                  legend: {
                    display: false, // Disable the legend
                  },
                },
                onClick: (event, elements) => handleDoughnutClick(event, elements),
              }}
              style={{ maxHeight: '200px', maxWidth: '200px' }} // Increase size as needed
            />
            </Paper>

               <OpenAIResponse query={query} />
          </YouTubeResultsWrapper>


        

         <LoginModal
          open={isLoginModalOpen}
          onClose={() => setIsLoginModalOpen(false)}
          onLoginSuccess={handleLoginSuccess}
        />

       <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="article-details-title"
          aria-describedby="article-details-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              right: '1%',
              transform: 'translate(0%, -50%)',
              width: '50%',
              bgcolor: '#f0fafa',
              boxShadow: 24,
              p: 1,
              overflow: 'auto',
              maxHeight: '100vh',
              [theme.breakpoints.down('md')]: {
                width: '90%', // Width adjustment for medium screens
              },
              [theme.breakpoints.down('sm')]: {
                width: '90%', // Width adjustment for small screens
                maxHeight: '70vh', // Adjust height for smaller screens
              },
            }}
          >
            {articleSource === 'eBooks' && (
              <ArticleDetailsDSpace
                selectedArticleId={selectedArticleId} // Pass selected article ID
                closeModal={() => setModalOpen(false)} // Function to close the modal
              />
            )}

          </Box>
        </Modal>
        </Box>
        )}
    </StyledContainer>
    </div>
  );
};

SingleSearch.propTypes = {
  query: PropTypes.string.isRequired,
};

export default SingleSearch;
