import React, { useState } from 'react'; // Removed useEffect
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Alert } from '@mui/material';

const LoginModal = ({ open, onClose, onLoginSuccess = () => {} }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleLogin = async (event) => {
    event.preventDefault();
    setError(null);
  
    try {
      const response = await axios.post('https://testadmin.remotlog.com/auth/proxy/login', {
        UserName: username,
        Password: password,
        Library: '6656fc8fad251455b687add2',
      });
  
      if (response.status === 200 && response.data && response.data.token) {
        const { token } = response.data;
        const decodedUser = jwtDecode(token);
  
        console.log('Decoded User:', decodedUser); // Check the user details
  
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(decodedUser));
  
        console.log('Triggering onLoginSuccess...');
        onLoginSuccess();
        onClose();
      } else {
        setError('Invalid credentials. Please try again.');
      }
    } catch (error) {
      console.error(error.response?.data || error.message); // Log error details
      
      if (error.response && error.response.status === 422) {
        setError('Username and password are required.');
      } else if (error.response && error.response.data) {
        setError(error.response.data.msg || 'Login failed. Please try again.');
      } else {
        setError('Unexpected error occurred.');
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Login</DialogTitle>
      <form onSubmit={handleLogin}>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          <TextField
            autoFocus
            margin="dense"
            label="Username"
            type="text"
            fullWidth
            value={username}
            onChange={(e) => { setUsername(e.target.value); setError(null); }}
          />
          <TextField
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => { setPassword(e.target.value); setError(null); }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" color="primary">Login</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default LoginModal;
