import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Paper, Typography, Card, CardContent, Button, IconButton, Box, Link, Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import FlipbookModal from '../components/FlipbookModal';

const ArticleDetailsDSpace = ({ selectedArticleId, closeModal }) => {
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bitstreamLoading, setBitstreamLoading] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);

  const openBitstreamContent = async () => {
    if (!id && !selectedArticleId) {
      return;
    }
  
    const itemId = id || selectedArticleId;
    setBitstreamLoading(true);
  
    try {
      const bundlesResponse = await axios.get(`/api/server/api/core/items/${itemId}/bundles`);
      const firstBundle = bundlesResponse.data._embedded.bundles[0];
      const bundleUuid = firstBundle.uuid;
  
      const bitstreamsResponse = await axios.get(`/api/server/api/core/bundles/${bundleUuid}/bitstreams`);
      const firstBitstream = bitstreamsResponse.data._embedded.bitstreams[0];
      const bitstreamUuid = firstBitstream.uuid;
  
      // Construct the content URL
      const contentUrl = `/api/server/api/core/bitstreams/${bitstreamUuid}/content`;
  
      // Check if the bitstream filename contains ".pdf"
      if (firstBitstream.name.toLowerCase().endsWith('.pdf')) {
        // Set the pdfUrl to open FlipbookModal with the PDF content
        setPdfUrl(contentUrl);
      } else {
        // Open the `dc.identifier.uri` link in a new tab if it is not a PDF
        if (item.link) {
          window.open(item.link, '_blank');
        } else {
          setError('No valid URI found to open the document.');
        }
      }
    } catch (error) {
      console.error('Error opening bitstream content:', error.message, error.response?.data || 'No additional error details');
      setError('Failed to open bitstream content.');
    } finally {
      setBitstreamLoading(false);
    }
  };
  
  

  useEffect(() => {
    const fetchItem = async () => {
      if (!selectedArticleId) return;
      setLoading(true);
      try {
        const response = await axios.get(`/api/server/api/core/items/${selectedArticleId}?embed=thumbnail`);
        const metadata = response.data.metadata;
        setItem({
          id: response.data.uuid,
          title: metadata['dc.title']?.[0]?.value || 'Untitled',
          author: metadata['dc.contributor.author']?.[0]?.value || 'Unknown Author',
          subject: metadata['dc.subject']?.[0]?.value || 'Unknown Subject',
          publisher: metadata['dc.publisher']?.[0]?.value || 'Unknown Publisher',
          date: metadata['dc.date.issued']?.[0]?.value || 'Unknown Date',
          description: metadata['dc.description.abstract']?.[0]?.value || 'No description available',
          link: metadata['dc.identifier.uri']?.[0]?.value,
          thumbnail: response.data._embedded?.thumbnail?._links?.content?.href || '/DefaultThumbnail.png'
        });
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch item details.');
        setLoading(false);
      }
    };
    fetchItem();
  }, [selectedArticleId]);

  const toggleDescription = () => setShowFullDescription(!showFullDescription);

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>{error}</Typography>;

  return (
    <Paper
      sx={{
        padding: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'red',
        position: 'relative',
        overflow: 'hidden'
      }}
      elevation={0}
    >
      <IconButton
        onClick={closeModal}
        sx={{ position: 'absolute', top: 10, right: 10, color: 'red' }}
      >
        <CloseIcon />
      </IconButton>
      <Card sx={{ backgroundColor: '#FFF2F2', padding: 2, boxSizing: 'border-box' }}>
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <img src={item.thumbnail} alt="Thumbnail" style={{ width: '20%', height: '30%', borderRadius: 5 }} />
            <Box sx={{ flex: 1 }}>
              <Typography variant="h5" sx={{ color: 'darkblue', marginBottom: 1 }}>
                {item.title}
              </Typography>
              <Typography sx={{ color: 'darkblue' }}>
                <strong>Author:</strong> {item.author}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 1 }}>
                <strong>Year:</strong> {item.date} | <strong>Publisher:</strong> {item.publisher}
              </Typography>
          
            </Box>
          </Box>

          <Box sx={{ marginTop: 2 }}>
            <Typography sx={{ marginBottom: 1, color: 'darkblue' }}>
              <strong>Description:</strong> {showFullDescription ? item.description : `${item.description.slice(0, 600)}...`}
              {item.description.length > 200 && (
                <Button variant="text" onClick={toggleDescription} sx={{ color: 'red', fontSize: '12px' }}>
                  {showFullDescription ? 'See Less' : 'See More'}
                </Button>
              )}
            </Typography>
            <Typography sx={{ color: 'darkblue' }}>
              <strong>Subject:</strong> {item.subject}
            </Typography>

            <Button
              onClick={openBitstreamContent}
              disabled={bitstreamLoading}
              sx={{ color: 'white', background: 'red', marginTop: 2 }}
            >
              {bitstreamLoading ? 'Opening Bitstream...' : 'Read Full-text'}
            </Button>

          </Box>
        </CardContent>
      </Card>
      {/* FlipbookModal will open as a popup overlay */}
      {pdfUrl && (
        <Dialog
          open={Boolean(pdfUrl)}
          onClose={() => setPdfUrl(null)}
          maxWidth="lg"
          fullWidth
        >
          <FlipbookModal
            pdfUrl={pdfUrl}
            onClose={() => setPdfUrl(null)} // Close modal by clearing pdfUrl
          />
        </Dialog>
      )}
    </Paper>
  );
};

export default ArticleDetailsDSpace;
