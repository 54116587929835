// src/components/Pagination.js
import React from 'react';
import './Pagination.css'; // Import the CSS file

const Pagination = ({ page, totalPages, onPageChange }) => {
  const handlePrevious = () => {
    if (page > 1) {
      onPageChange(page - 1);
    }
  };

  const handleNext = () => {
    if (page < totalPages) {
      onPageChange(page + 1);
    }
  };

  const handleFirst = () => {
    if (page !== 1) {
      onPageChange(1);
    }
  };

  const handleLast = () => {
    if (page !== totalPages) {
      onPageChange(totalPages);
    }
  };

  const getPages = () => {
    const pages = [];
    const maxVisiblePages = 10;
    const halfRange = Math.floor(maxVisiblePages / 2);

    let startPage = Math.max(1, page - halfRange);
    let endPage = Math.min(totalPages, page + halfRange);

    if (startPage === 1) {
      endPage = Math.min(totalPages, maxVisiblePages);
    }
    if (endPage === totalPages) {
      startPage = Math.max(1, totalPages - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  return (
    <div className="pagination">
      <button onClick={handleFirst} disabled={page === 1}>
        &laquo; First
      </button>
      <button onClick={handlePrevious} disabled={page === 1}>
        &lt;
      </button>
      {getPages().map((pageNum) => (
        <button
          key={pageNum}
          className={pageNum === page ? 'active' : ''}
          onClick={() => onPageChange(pageNum)}
        >
          {pageNum}
        </button>
      ))}
      <button onClick={handleNext} disabled={page === totalPages}>
        &gt;
      </button>
      <button onClick={handleLast} disabled={page === totalPages}>
        Last &raquo;
      </button>
    </div>
  );
};

export default Pagination;
