import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MicIcon from '@mui/icons-material/Mic';
import './SearchBox1.css';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = SpeechRecognition ? new SpeechRecognition() : null;

const SearchBox = ({ onSearch, initialQuery, selectedDatabase }) => {
  const [inputValue, setInputValue] = useState(initialQuery || '');
  const [isListening, setIsListening] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedDatabaseState, setSelectedDatabaseState] = useState(
    selectedDatabase || 'All Databases'
  );

  // Sync inputValue with URL query changes
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get('query') || '';
    setInputValue(query);
  }, [location]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSearch = () => {
    const query = (inputValue || '').trim();
    if (!query) return;

    switch (selectedDatabase) {
      case 'All Databases':
        navigate(`/single-search?query=${encodeURIComponent(query)}`);
        break;
      case 'eBooks':
        navigate(`/EBooks?query=${encodeURIComponent(query)}`);
        break;
      case 'eJournals':
        navigate(`/EJournals?query=${encodeURIComponent(query)}`);
        break;
      case 'Journal Articles':
        navigate(`/Journal_Articles?query=${encodeURIComponent(query)}`);
        break;
      case 'Leisure Readings':
        navigate(`/Leisure_Readings?query=${encodeURIComponent(query)}`);
        break;
      default:
        console.error('Invalid database selection.');
    }
  };

  const handleDatabaseChange = (event) => {
    const selectedDatabase = event.target.value;
    setSelectedDatabaseState(selectedDatabase);

    const query = (inputValue || '').trim(); // Use the current search term
    if (!query) return;

    switch (selectedDatabase) {
      case 'All Databases':
        navigate(`/single-search?query=${encodeURIComponent(query)}`);
        break;
      case 'eBooks':
        navigate(`/EBooks?query=${encodeURIComponent(query)}`);
        break;
      case 'eJournals':
        navigate(`/EJournals?query=${encodeURIComponent(query)}`);
        break;
      case 'Journal Articles':
        navigate(`/Journal_Articles?query=${encodeURIComponent(query)}`);
        break;
      case 'Leisure Readings':
        navigate(`/Leisure_Readings?query=${encodeURIComponent(query)}`);
        break;
      default:
        console.error('Invalid database selection.');
    }
  };

  const handleClear = () => {
    setInputValue('');
    setError(null);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const startListening = () => {
    if (recognition) {
      recognition.start();
      setIsListening(true);
    }
  };

  const stopListening = () => {
    if (recognition) {
      recognition.stop();
      setIsListening(false);
    }
  };

  if (recognition) {
    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setInputValue(transcript);
      onSearch(transcript);
      handleSearch(transcript);
    };

    recognition.onerror = () => {
      setIsListening(false);
    };
  }

  return (
    <div className="content-wrapper">
      <div className="search-box1">
        {/* Dropdown Menu */}
        <select
          className="database-dropdown1"
          value={selectedDatabaseState}
          onChange={handleDatabaseChange}
        >
          <option value="All Databases">All Databases</option>
          <option value="eBooks">eBooks</option>
          <option value="eJournals">eJournals</option>
          <option value="Journal Articles">Journal Articles</option>
          <option value="Leisure Readings">Leisure Readings</option>
        </select>

        {/* Search Box */}
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Enter Search Term ..."
          className="search-input1"
        />
        <button
          onMouseDown={startListening}
          onMouseUp={stopListening}
          onTouchStart={startListening}
          onTouchEnd={stopListening}
          className={`voice-btn1 ${isListening ? 'listening' : ''}`}
          aria-label="Voice Search"
        >
          <MicIcon />
        </button>
        {inputValue && (
          <button onClick={handleClear} className="clear-btn1">
            ✖
          </button>
        )}
        <button onClick={() => handleSearch()} className="search-btn1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="search-icon"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-4.35-4.35M18 11a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default SearchBox;
