import axios from 'axios';

// Fetch the API URLs and Key from environment variables
const API_URL = process.env.REACT_APP_JGATE_API_URL;
const API_KEY = process.env.REACT_APP_JGATE_API_KEY;

const fetchJGateResults = async (query, page, sorting, resultsPerPage) => {
  try {

    const response = await axios.get('https://demosearchapi.jgatenext.com/api/search', {
      params: {
        searchterm: query,
        page: page - 1,
        per_page: resultsPerPage,
        sort: `${sorting.field},${sorting.direction}`,
      },
      headers: {
        'Api-Key': 'a3b1726d5c2a4e7b8e6b3d5d7a9f1e2a'
      },
    });

    const docs = response.data.data.docs || [];
    const total = response.data.data.hits || docs.length;

    const formattedResults = docs.map(result => ({
      title: result.title,
      abstract: result.abstract || result.about_resource || 'No abstract available',
      authors: result.authors?.join(', ') || 'Unknown',
      year: new Date(result.dateofpublication).getFullYear(),
      source: 'Journal Articles',
      //detailLink: `${jgateProxyURL}/abstractFullScreen?id=${result.article_id}`,
      detailLink: `https://jgatenext-com.demo.remotlog.com/abstractFullScreen?id=${result.article_id}`,
    }));

    return { results: formattedResults, total };
  } catch (error) {
    console.error('Error fetching JGate results:', error);
    return { results: [], total: 0 };
  }
};

export default fetchJGateResults;
