import axios from 'axios';

// Fetch the API URLs and Key from environment variables
const API_URL = 'https://demosearchapi.jgatenext.com/api/search';
const API_KEY = 'a3b1726d5c2a4e7b8e6b3d5d7a9f1e2a';

const fetchJGateResults = async (query, page, sorting, resultsPerPage) => {
  try {

    const response = await axios.get(API_URL, {
      params: {
        searchterm: query,
        page: page - 1,
        per_page: resultsPerPage,
        sort: `${sorting.field},${sorting.direction}`,
        includeFacets: true,
      },
      headers: {
        'Api-Key': API_KEY,
      },
    });

    const docs = response.data?.data?.docs || [];
    const total = response.data?.data?.hits || 0;

    const facets = {
      journals: {},
      authors: {},
      subjects: {},
      publishers: {},
      countries: {},
    };
    
    docs.forEach(doc => {
      // Aggregate journals
      const journal = doc.journal_name;
      if (journal) {
        facets.journals[journal] = (facets.journals[journal] || 0) + 1;
      }
    
      // Aggregate authors
      (doc.authors || []).forEach(author => {
        facets.authors[author] = (facets.authors[author] || 0) + 1;
      });
    
      // Aggregate subjects
      (doc.subjects_name_l3 || []).forEach(subject => {
        facets.subjects[subject] = (facets.subjects[subject] || 0) + 1;
      });
    
      // Aggregate publishers
      (doc.publisher_name || []).forEach(publisher => {
        facets.publishers[publisher] = (facets.publishers[publisher] || 0) + 1;
      });
    
      // Aggregate countries
      const country = doc.primary_publisher_country;
      if (country) {
        facets.countries[country] = (facets.countries[country] || 0) + 1;
      }
    });
    
    // Helper function to sort and slice facets
    const sortAndSliceFacets = (facetObj) =>
      Object.entries(facetObj)
        .sort(([, countA], [, countB]) => countB - countA) // Sort by count descending
        .slice(0, 5)
        .reduce((acc, [key, count]) => ({ ...acc, [key]: count }), {});
    
    // Limit facets to top 5
    const formattedFacets = {
      journals: sortAndSliceFacets(facets.journals),
      authors: sortAndSliceFacets(facets.authors),
      subjects: sortAndSliceFacets(facets.subjects),
      publishers: sortAndSliceFacets(facets.publishers),
      countries: sortAndSliceFacets(facets.countries),
    };
    

    const formattedResults = docs.map(result => ({
      title: result.title,
      abstract: result.abstract || result.about_resource || 'No abstract available',
      authors: result.authors?.join(', ') || 'Unknown',
      year: new Date(result.dateofpublication).getFullYear(),
      journal_name: result.journal_name || 'No Journal Name',
      publisher_name: result.publisher_name || 'No Publisher Name',
      articledoi: result.articledoi || 'No DOI',
      subjects_name_l3: result.subjects_name_l3?.join(', ') || 'Unknown',
      source: 'Journal Articles',
      //detailLink: `${jgateProxyURL}/abstractFullScreen?id=${result.article_id}`,
      detailLink: `https://jgatenext-com.demo.remotlog.com/abstractFullScreen?id=${result.article_id}`,
    }));

    return { results: formattedResults, total, facets: formattedFacets };
  } catch (error) {
    console.error('Error fetching JGate results:', error);
    return { results: [], total: 0, facets: { subjects: {}, publishers: {}, countries: {} } };
  }
}

export default fetchJGateResults;
