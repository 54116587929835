// File path: src/components/Facets.js

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Checkbox, FormControlLabel } from '@mui/material';

const Facets = ({ facets, selectedFacets, onFacetChange }) => {
  const handleChange = (facetName, value) => {
    onFacetChange(facetName, value);
  };

  return (
    <Box>
    {Object.keys(facets).map((facetName) => (
      <Box key={facetName} sx={{ marginBottom: '12px' }}>
        <Typography
          variant="h6"
          sx={{ fontSize: '1rem', color: 'darkblue', marginBottom: '8px', fontWeight: 'bold' }}
        >
          {facetName}
        </Typography>
        {Object.keys(facets[facetName]).map((value) => (
          <FormControlLabel
            key={value}
            control={
              <Checkbox
                checked={selectedFacets[facetName] === value}
                onChange={() => handleChange(facetName, value)}
                sx={{
                  color: 'red',
                  '&.Mui-checked': {
                    color: 'red',
                  },
                }}
              />
            }
            label={
              <Typography sx={{ fontSize: '0.875rem', color: 'darkblue' }}>
                {`${value} (${facets[facetName][value]})`}
              </Typography>
            }
            sx={{ display: 'flex', alignItems: 'center', margin: '-12px 0' }}
          />
        ))}
      </Box>
    ))}
  </Box>
  );
};

Facets.propTypes = {
  facets: PropTypes.object.isRequired,
  selectedFacets: PropTypes.object.isRequired,
  onFacetChange: PropTypes.func.isRequired,
};

export default Facets;
