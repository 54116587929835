import React, { useState } from 'react';
import emailjs from 'emailjs-com'; // Install EmailJS: npm install emailjs-com
import '../components/ContactUs.css'; // Ensure the CSS is in the same directory.

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(
        'YOUR_SERVICE_ID', // Replace with your EmailJS service ID
        'YOUR_TEMPLATE_ID', // Replace with your EmailJS template ID
        {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          message: formData.message,
        },
        'jagannath.m@informaticsglobal.ai' // Replace with your EmailJS user ID
      )
      .then(
        (response) => {
          alert('Message sent successfully!');
          setFormData({ name: '', email: '', phone: '', message: '' });
        },
        (error) => {
          console.error('Failed to send message:', error);
          alert('Failed to send message. Please try again later.');
        }
      );
  };

  return (
    <div>
    <div className="contact-us-container">
      <div className="contact-info"><br></br>
        <h2>Contact Information</h2>
        {/* Contact details */}
        <div className="contact-item">
          <i className="icon calendar-icon"></i>
          <div>
            <h3>Appointment</h3>
            <p>
              For a prior appointment to meet the Director, UPSIFS, Lucknow
              during office hours (11:00 AM to 5:00 PM), kindly contact/WhatsApp on CUG No.
              <strong> +91-78390-01311.</strong>
            </p>
          </div>
        </div>
        {/* Additional contact information */}
        <div className="contact-item">
          <i className="icon location-icon"></i>
          <div>
            <h3>Address</h3>
            <p>
              Uttar Pradesh State Institute of Forensic Science <br />
              Aurawan, P.O. Banthra, <br />
              Lucknow-226401 (U.P)
            </p>
          </div>
        </div>
        <div className="contact-item">
          <i className="icon phone-icon"></i>
          <div>
            <h3>Make a Call</h3>
            <p>
              <strong>+91-78390-01310</strong>
            </p>
          </div>
        </div>
        <div className="contact-item">
          <i className="icon mail-icon"></i>
          <div>
            <h3>Send a Mail</h3>
            <p>
              <strong>upsifsup@gmail.com</strong>
            </p>
          </div>
        </div>
      </div>
      <div className="contact-form"><br></br>
        <h2>Need Help? <span>Keep in Touch</span></h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email*"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <textarea
              name="message"
              placeholder="Tell Us More Details *"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit">SEND MESSAGE</button>
        </form>
      </div>
    </div>
    <div className="google-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3563.6246546854613!2d80.83263097366309!3d26.724435568159638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bf90feea5a8ad%3A0x2fd1a60a0c458785!2sUP%20State%20Institute%20of%20Forensic%20Sciences%20(UPSIFS)!5e0!3m2!1sen!2sin!4v1732264546903!5m2!1sen!2sin"
          width="100%"
          height="400"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title="UPSIFS Location"
        ></iframe>
      </div>

    </div>
  );
};

export default ContactUs;
