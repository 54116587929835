import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Alert } from '@mui/material';
import axios from 'axios';

const ForgotPasswordModal = ({ open, onClose }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handlePasswordReset = async () => {
    setError(null);
    setSuccess(null);
    try {
      const response = await axios.post('https://testadmin.remotlog.com/auth/forgot-password', { email });
      if (response.status === 200) {
        setSuccess('Password reset email sent successfully.');
      } else {
        setError('Failed to send password reset email. Please try again.');
      }
    } catch (error) {
      console.error(error);
      setError('An error occurred. Please try again later.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Forgot Password</DialogTitle>
      <DialogContent>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        <TextField
          autoFocus
          margin="dense"
          label="Email Address"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handlePasswordReset} color="primary">Send Reset Email</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ForgotPasswordModal;
