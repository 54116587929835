// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sort-by {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.sort-by label {
  margin-right: 10px;
}

.sort-by select {
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
  border-radius: 4px;
}

.sort-by select:hover {
  background-color: #f0f0f0;
}
`, "",{"version":3,"sources":["webpack://./src/components/SortBy.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,sBAAsB;EACtB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".sort-by {\r\n  display: flex;\r\n  align-items: center;\r\n  padding: 8px;\r\n  background-color: #fff;\r\n  border: 1px solid #ddd;\r\n  border-radius: 4px;\r\n}\r\n\r\n.sort-by label {\r\n  margin-right: 10px;\r\n}\r\n\r\n.sort-by select {\r\n  padding: 5px;\r\n  border: 1px solid #ddd;\r\n  background-color: #fff;\r\n  cursor: pointer;\r\n  border-radius: 4px;\r\n}\r\n\r\n.sort-by select:hover {\r\n  background-color: #f0f0f0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
