// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Pagination.css */

.pagination {
  display: flex;
  justify-content: center;
  gap: 5px;
  padding: 10px;
}

.pagination button {
  padding: 8px 12px;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.pagination button:hover {
  background-color: #f0f0f0;
}

.pagination button.active {
  background-color: red;
  color: #fff;
  border-color: red;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}


`, "",{"version":3,"sources":["webpack://./src/components/Pagination.css"],"names":[],"mappings":"AAAA,kCAAkC;;AAElC;EACE,aAAa;EACb,uBAAuB;EACvB,QAAQ;EACR,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,sBAAsB;EACtB,eAAe;EACf,kBAAkB;EAClB,6CAA6C;AAC/C;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd","sourcesContent":["/* src/components/Pagination.css */\r\n\r\n.pagination {\r\n  display: flex;\r\n  justify-content: center;\r\n  gap: 5px;\r\n  padding: 10px;\r\n}\r\n\r\n.pagination button {\r\n  padding: 8px 12px;\r\n  border: 1px solid #ddd;\r\n  background-color: #fff;\r\n  cursor: pointer;\r\n  border-radius: 4px;\r\n  transition: background-color 0.3s, color 0.3s;\r\n}\r\n\r\n.pagination button:hover {\r\n  background-color: #f0f0f0;\r\n}\r\n\r\n.pagination button.active {\r\n  background-color: red;\r\n  color: #fff;\r\n  border-color: red;\r\n}\r\n\r\n.pagination button:disabled {\r\n  cursor: not-allowed;\r\n  opacity: 0.5;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
