import React from 'react';
import './SortBy.css';

const SortBy = ({ sortOrder, onSortChange }) => {
  return (
    <div className="sort-by">
      <label htmlFor="sortOrder">Sort by: </label>
      <select id="sortOrder" value={sortOrder} onChange={(e) => onSortChange(e.target.value)}>
        <option value="relevance">Relevance</option>
        <option value="alphabetical">Alphabetical</option>
        <option value="latest">Latest</option>
      </select>
    </div>
  );
};

export default SortBy;
