import React, { useState, useEffect } from 'react';
import MicIcon from '@mui/icons-material/Mic';
import './SearchBox.css';
import { useNavigate, useLocation } from 'react-router-dom';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = SpeechRecognition ? new SpeechRecognition() : null;

const SearchBox = ({ onSearch, initialQuery }) => {
  const [inputValue, setInputValue] = useState(initialQuery || '');
  const [isListening, setIsListening] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Extract path once and reuse it
  const path = location.pathname.slice(1); // Remove the leading '/'

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSearch = (searchQuery) => {
    const query = (searchQuery || inputValue || '').toString().trim();
    if (!query) {
      setError('Search query cannot be empty.');
      return;
    }
    if (typeof onSearch === 'function') {
      onSearch(query); // Call onSearch if it is a valid function
    } else {
      console.error('onSearch is not a function. Please pass a valid onSearch prop.');
    }
  };
  

  const handleClear = () => {
    setInputValue('');
  };
  

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const startListening = () => {
    if (recognition) {
      recognition.start();
      setIsListening(true);
    }
  };

  const stopListening = () => {
    if (recognition) {
      recognition.stop();
      setIsListening(false);
    }
  };

  if (recognition) {
    recognition.onresult = (event) => {
      const transcript = (event.results[0][0]?.transcript || '').trim();
      if (transcript) {
        setInputValue(transcript);
        onSearch(transcript);
        handleSearch(transcript);
      }
    };
  }

  return (
    <div className="homepage">
      <div className="dark-overlay">
        <div className="content-wrapper">
          {/* Title Section */}
          <div className="title-section">
            <h1>Welcome to the Digital Library of</h1>
            <h2>Uttar Pradesh State Institute of Forensic Science</h2>
            (Affiliated with NFSU, Gandhinagar and Approved by AICTE, BCI, Government of India)
          </div>

          {/* Search Box */}
          <div className="search-box">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder="Enter Search Term ..."
              className="search-input"
            />
            <button
              onMouseDown={startListening}
              onMouseUp={stopListening}
              onTouchStart={startListening}
              onTouchEnd={stopListening}
              className={`voice-btn ${isListening ? 'listening' : ''}`}
              aria-label="Voice Search"
            >
              <MicIcon />
            </button>
            {inputValue && (
              <button onClick={handleClear} className="clear-btn">
                ✖
              </button>
            )}
            <button onClick={() => handleSearch()} className="search-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="search-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-4.35-4.35M18 11a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
