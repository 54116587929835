import React, { useEffect, useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import './FlipbookModal.css';

const FlipbookModal = ({ pdfUrl, onClose }) => {
  const [isMobile, setIsMobile] = useState(false);
  // Initialize only the default layout plugin
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    // Detect mobile view
    const updateView = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', updateView);
    updateView(); // Initial check

    return () => {
        window.removeEventListener('resize', updateView);
    };
}, []);


  return (
    <div
      className="modal-overlay"
      onClick={onClose}
      onContextMenu={(e) => e.preventDefault()}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: isMobile ? 'flex-start' : 'center', // Align to top for mobile
        zIndex: 1000, // Ensure modal overlay is above other content
      }}
    >
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
        style={{
          position: 'relative',
          backgroundColor: '#fff',
          padding: '20px',
          borderRadius: '8px',
          maxHeight: '90vh',
          overflowY: 'auto',
          width: '100%',
          maxWidth: isMobile ? '90%' : 'none', // Adjust width for mobile
          zIndex: 1050, // Ensure content is above modal overlay
        }}
      >
        <button
          className="close-button"
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '3px',
            right: '10px',
            backgroundColor: 'red',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            padding: '5px 5px',
            cursor: 'pointer',
            zIndex: 1100, // Ensure it's above other content
          }}
        >
          Close
        </button>

        {/* PDF Viewer Container */}
        <div
          style={{
            height: '86vh', // Make sure the content area has a fixed height
            overflowY: 'auto', // Enable scrolling for the PDF content
          }}
        >
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <Viewer
              fileUrl={pdfUrl}
              defaultScale={1.0}
              plugins={[defaultLayoutPluginInstance]}
            />
          </Worker>
        </div>
      </div>
    </div>
  );
};

export default FlipbookModal;
