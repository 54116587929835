import React from 'react';
import '../components/AboutUs.css'; // Make sure the CSS is in the same directory or update the path accordingly.

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <header className="about-us-header">
        <h1><br></br></h1>
      </header>
      <section className="about-us-section">
      <h2>About UPSIFS</h2>
        <p>
          Established in 2023, the UPSIFS campus is an innovative campus focused
          on Forensic Science learning including Cyber Security, DNA Forensics,
          Forensic Document Examination, Forensic Ballistics, etc.
        </p>
        <ul>
          <li>School of Forensic Science</li>
          <li>School of Legal Studies</li>
          <li>School of Science and Technology</li>
        </ul>
      </section>
      <section className="about-us-section">
        <h2>Vision & Objective</h2>
        <p><strong>(AN INSTITUTION OF NATIONAL IMPORTANCE)</strong></p>
        <ol>
          <li>
            To create a pool of trained manpower in the field of forensics who
            can be recruited for various positions in forensic laboratories all
            over the country.
          </li>
          <li>
            To train the police personnel as well as the members of the judiciary
            in the field of forensic sciences.
          </li>
          <li>
            To develop the knowledge of science, technology, providing specially
            designed academic training programs in various technologies related
            to forensic science and behavioral science and also advanced
            technologies of policing and forensic science and behavioral science,
            which includes both theoretical input and practical training for
            forensic and other applications; to generate expertise in advanced
            technologies required in investigation and handling of crime cases.
          </li>
          <li>
            To create capabilities for upgrading the infrastructure to global
            standards for education, training and research in the areas related
            to policing and forensic science, technology, criminology, and
            applied behavioral science.
          </li>
          <li>
            To function as a leading resource center for knowledge and
            development in the areas of forensic science, behavioral science
            related to civil laws related to police matter and criminal laws.
          </li>
          <li>
            To establish close linkages with various forensic science
            laboratories, institutes, law enforcement authorities, and
            institutions to make teaching, training, and research relevant in
            policing and forensic science.
          </li>
        </ol>
      </section>
    </div>
  );
};

export default AboutUs;
