import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Button, Box, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import remotlogLogo from '../assets/logo3.png';
import informaticsLogo from '../assets/UPSIFS_Logo.png';
import LoginModal from './LoginModal';  // Import the LoginModal component
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const navigate = useNavigate();

  const [browseAnchorEl, setBrowseAnchorEl] = useState(null);

  const handleBrowseMenuClick = (event) => {
    setBrowseAnchorEl(event.currentTarget);
  };
  
  const handleBrowseMenuClose = () => {
    setBrowseAnchorEl(null);
  };

  // Check for login status on mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUser = JSON.parse(localStorage.getItem('user')); // Outer user object
    if (token && storedUser?.user) {
      console.log('User on mount:', storedUser.user); // Inner user object
      setIsLoggedIn(true);
      setUserName(
        storedUser.user.DisplayName?.replace(/&nbsp;/g, ' ') ||
        `${storedUser.user.FirstName} ${storedUser.user.LastName}`.trim() ||
        'User'
      );
    }
  }, []);

  const handleLoginSuccess = () => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    console.log('User from localStorage:', storedUser.user); // Debug the inner user object
    setIsLoggedIn(true);
    setUserName(
      storedUser.user.DisplayName?.replace(/&nbsp;/g, ' ') ||
      `${storedUser.user.FirstName} ${storedUser.user.LastName}`.trim() ||
      'User'
    );
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setIsLoggedIn(false);
    setUserName('');
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAccountMenuClick = (event) => {
    setAccountMenuAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClose = () => {
    setAccountMenuAnchorEl(null);
  };



  const handleLoginClick = () => {
    setOpenLoginModal(true); // Open the login modal
    handleAccountMenuClose(); // Close the account menu
  };

  const handleCloseLoginModal = () => {
    setOpenLoginModal(false); // Close the login modal
  };





  return (
    <>
      <AppBar position="fixed" sx={{ backgroundColor: '#ffffff', boxShadow: 'none', borderBottom: '1px solid #e0e0e0' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box display="flex" alignItems="center">
            <IconButton edge="start" color="inherit" aria-label="home" component={Link} to="/">
              <img src={informaticsLogo} alt="Informatics Logo" style={{ height: 70 }} />
            </IconButton>
          </Box>
          {isMobile ? (
            <Box display="flex" alignItems="center">
              <IconButton edge="end" color="#018780" aria-label="menu" onClick={handleMenuClick}>
                <MenuIcon />
              </IconButton>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose} keepMounted>
                <MenuItem component={Link} to="/" onClick={handleMenuClose}>Single Search</MenuItem>
                <MenuItem component={Link} to="/EBooks" onClick={handleBrowseMenuClose}>eBooks</MenuItem>
                <MenuItem component={Link} to="/EJournals" onClick={handleBrowseMenuClose}>eJournals</MenuItem>
                <MenuItem component={Link} to="/AI_Assistant" onClick={handleMenuClose}>AI Assistant</MenuItem>
                <MenuItem component={Link} to="/AboutUs" onClick={handleMenuClose}>About Us</MenuItem>
                <MenuItem component={Link} to="/ContactUs" onClick={handleMenuClose}>Contact Us</MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box display="flex" alignItems="center">
              <Button component={Link} to="/" sx={{ color: 'darkblue', mx: 1, textTransform: 'none' }}><b>iDisKover</b></Button>
              <Button
                sx={{ color: 'darkblue', mx: 1, textTransform: 'none' }}
                onClick={handleBrowseMenuClick}
                endIcon={<ArrowDropDownIcon />}
              >
                <b>Browse E-Resources</b>
              </Button>
              <Menu
                anchorEl={browseAnchorEl}
                open={Boolean(browseAnchorEl)}
                onClose={handleBrowseMenuClose}
                keepMounted
              >
                <MenuItem component={Link} to="/EBooks" onClick={handleBrowseMenuClose}>eBooks</MenuItem>
                <MenuItem component={Link} to="/EJournals" onClick={handleBrowseMenuClose}>eJournals</MenuItem>
              </Menu>
              <Button component={Link} to="/AI_Assistant" sx={{ color: 'darkblue', mx: 1, textTransform: 'none' }}><b>AI Assistant</b></Button>
              <Button component={Link} to="/AboutUs" sx={{ color: 'darkblue', mx: 1, textTransform: 'none' }}><b>About Us</b></Button>
              <Button component={Link} to="/ContactUs" sx={{ color: 'darkblue', mx: 1, textTransform: 'none' }}><b>Contact Us</b></Button>
            </Box>
          )}
          <Box display="flex" alignItems="center">
            <IconButton edge="end" color="#f9fcfc" onClick={handleAccountMenuClick}>
              <AccountCircle sx={{ fontSize: 40, color: 'red' }} />
            </IconButton>
            <Menu anchorEl={accountMenuAnchorEl} open={Boolean(accountMenuAnchorEl)} onClose={handleAccountMenuClose}>
              {isLoggedIn ? (
                <>
                  <MenuItem disabled>{`Hello, ${userName}`}</MenuItem>
                  <MenuItem component={Link} to="/change-password">Change Password</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </>
              ) : (
                <>
                  <MenuItem component={Link} to="/login/#login">Login</MenuItem>
                  <MenuItem component={Link} to="/forgot-password">Forgot Password</MenuItem>
                </>
              )}
            </Menu>
            <IconButton edge="end" color="inherit" aria-label="remotlog">
              <img src={remotlogLogo} alt="Remotlog Logo" style={{ height: 30 }} />
            </IconButton>
          </Box>


        </Toolbar>
      </AppBar>

      {/* Render Modals */}
      <LoginModal 
        open={openLoginModal} 
        onClose={() => setOpenLoginModal(false)} 
        onLoginSuccess={handleLoginSuccess} 
      />

    </>
  );
};

export default Header;
