import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, Card, CardContent, Grid } from '@mui/material';
import './SearchResults.css';
import defaultThumbnail from '../assets/logo4.png';

const stripHTML = (text) => {
  if (!text) return '';
  return text.replace(/<[^>]+>/g, '');
};

const highlightText = (text, keyword) => {
  if (!keyword || !text) return text;

  // Escape special regex characters in the keyword to avoid breaking the regex pattern.
  const escapedKeyword = keyword.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  const regex = new RegExp(`(${escapedKeyword})`, 'gi');

  // Split text by the keyword regex and wrap matches in a span with the "highlight" class.
  return text.split(regex).map((part, index) =>
    part.toLowerCase() === keyword.toLowerCase() ? (
      <span key={index} className="highlight">
        {part}
      </span>
    ) : (
      part
    )
  );
};

const SearchResults = ({ results, viewMode, onTitleClick, searchQuery }) => {
  return (
    <Paper sx={{ width: '100%', minHeight: '300px', backgroundColor: '#f9fcfc' }}>
      {viewMode === 'list' ? (
        results.map((result, index) => (
          <Card key={index}>
            <CardContent sx={{ backgroundColor: '#FFF2F2' }}>
              <Typography
                sx={{ fontSize: '14px', cursor: 'pointer', color: 'darkblue', textDecoration: 'none' }}
                onClick={() => onTitleClick(result)}
              >
                <b>{highlightText(result.title, searchQuery)}</b>
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                {highlightText(stripHTML(result.abstract), searchQuery)}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  color: 'text.secondary',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {highlightText(result.source, searchQuery)}, {highlightText(result.year, searchQuery)}{highlightText(result.yearRange, searchQuery)}, {highlightText(result.authors, searchQuery)}{highlightText(result.subjects, searchQuery)}, Published by: {highlightText(result.publisher, searchQuery)}
              </Typography>
            </CardContent>
          </Card>
        ))
      ) : (
        <Grid container spacing={2} sx={{ backgroundColor: '#FFF2F2' }}>
          {results.map((result, index) => (
            <Grid item xs={20} sm={6} md={3} key={index}>
              <Card sx={{ backgroundColor: '#FFF2F2' }}>
              <CardContent sx={{ backgroundColor: '#FFF2F2' }}>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <img
                      src={result.thumbnail || defaultThumbnail}
                      alt="Thumbnail"
                      style={{ width: '120px', height: '160px', borderRadius: 5, marginRight: '10px' }}
                    />
                  </div>
                  <Typography
                    sx={{ fontSize: '14px', cursor: 'pointer', color: 'darkblue', textDecoration: 'none' }}
                    onClick={() => onTitleClick(result)}
                  >
                    <b>{highlightText(result.title, searchQuery)}</b>
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {highlightText(stripHTML(result.abstract), searchQuery)}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      color: 'text.secondary',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {highlightText(result.source, searchQuery)}, {highlightText(result.year, searchQuery)}{highlightText(result.yearRange, searchQuery)}, {highlightText(result.authors, searchQuery)}{highlightText(result.subjects, searchQuery)}, Published by: {highlightText(result.publisher || result.publisher_name, searchQuery)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Paper>
  );
};

SearchResults.propTypes = {
  results: PropTypes.array.isRequired,
  viewMode: PropTypes.string.isRequired,
  searchQuery: PropTypes.string
};

export default SearchResults;
