import React, { useState } from 'react';
import axios from 'axios';
import LoginModal from '../components/LoginModal';
import {
  Modal,
  Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArticleDetailsDSpace from '../pages/ArticleDetails_DSpace'; // Import the ArticleDetailsDSpace component
import defaultThumbnail from '../assets/logo4.png'; // Adjust path as needed

const AI_Assistant = () => {
  const [messages, setMessages] = useState([]);
  const [userMessage, setUserMessage] = useState('');
  const theme = useTheme(); // Access the theme

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedArticleId, setSelectedArticleId] = useState(null);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const OPENAI_API_KEY = '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'; // Replace with your OpenAI API key
  const scopeIds = [
    { name: 'eBooks', scope: 'f86f89c4-00cc-4468-859f-8ca905c24475' },
    { name: 'Leisure Readings', scope: '7d5755db-57c4-45d5-accb-ab689bee03ce' },
  ];

  // Use OpenAI to process user queries and extract intent
  const processQueryWithOpenAI = async (message) => {
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'system',
              content: `You are a library assistant. Your job is to:
              1. Extract up to 2 concise, distinct, and relevant semantic search terms from the user's query.
              2. Ensure the terms are formatted as a single, comma-separated line.
              3. Avoid redundant or overly broad terms.`,
            },
            { role: 'user', content: message },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      const searchTerms = response.data.choices[0].message.content.trim();
      return searchTerms;
    } catch (error) {
      console.error('Error processing query with OpenAI:', error.message);
      return ''; // Fallback to an empty query if OpenAI fails
    }
  };

  // Fetch recommendations from DSpace using search terms
  const fetchRecommendations = async (query, scope) => {
    try {
      // Combine multiple search terms into a single query string
      const combinedQuery = query.join(' OR '); // Use 'AND' or 'OR' based on your use case
  
      const response = await axios.get(
        `/api/server/api/discover/search/objects?query=${encodeURIComponent(combinedQuery)}&scope=${scope}&embed=thumbnail&embed=item%2Fthumbnail`,
        {
          params: {
            size: 5, // Adjust this number as needed
          },
        }
      );
  
      const items = response.data._embedded.searchResult._embedded.objects;
  
      return items
        .filter((item) => item._embedded && item._embedded.indexableObject)
        .map((item) => {
          const indexableObject = item._embedded.indexableObject;
          const metadata = indexableObject.metadata;
          const thumbnail = indexableObject._embedded?.thumbnail?._links?.content?.href || defaultThumbnail;
  
          return {
            title: metadata['dc.title']?.[0]?.value || 'No title available',
            authors: metadata['dc.contributor.author']?.[0]?.value || 'Unknown',
            publisher: metadata['dc.publisher']?.[0]?.value || 'Unknown',
            link: `/article/dspace/${indexableObject.uuid}`,
            thumbnail,
          };
        });
    } catch (error) {
      console.error(`Error fetching recommendations for query "${query}" in scope "${scope}":`, error.message);
      return [];
    }
  };

  // Generate conversational recommendations
  const getRecommendations = async (extractedTerms) => {
    try {
      // Ensure extracted terms are formatted as an array
      const searchTerms = extractedTerms
      .split(/,|\n|-/)
      .map((term) => term.trim())
      .filter((term) => term.length > 0)
      .slice(0, 5); // Limit to 5 terms
  
      // Aggregate recommendations from all ScopeIDs
      const promises = scopeIds.map(async ({ name, scope }) => {
        const results = await fetchRecommendations(searchTerms, scope);
  
        return {
          collection: name,
          books: results,
        };
      });
  
      const recommendations = await Promise.all(promises);
  
      // Filter out empty results (if no books are found in a scope)
      return recommendations.filter((rec) => rec.books.length > 0);
    } catch (error) {
      console.error('Error fetching recommendations:', error.message);
      return [];
    }
  };

  

  // Format recommendations into a conversational reply
  const formatRecommendations = (recommendations) => {
    if (recommendations.length === 0) {
      return <p>I couldn’t find any books matching your query. Please try again with different keywords.</p>;
    }
  
    return recommendations.map((rec, recIndex) => (
      <div key={recIndex} style={{ marginBottom: '20px' }}>
        <h3 style={{ color: 'darkblue', marginBottom: '10px' }}>{rec.collection}</h3>
        {rec.books.map((book, bookIndex) => (
          <div
            key={bookIndex}
            style={{
              border: '1px solid #ddd',
              borderRadius: '5px',
              padding: '10px',
              marginBottom: '10px',
              backgroundColor: '#f9f9f9',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <img
                src={book.thumbnail}
                alt="Thumbnail"
                style={{
                  width: '60px',
                  height: '75px',
                  objectFit: 'cover',
                  borderRadius: '5px',
                }}
              />
              <div>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    onTitleClick(book);
                  }}
                  style={{
                    color: 'red',
                    textDecoration: 'none',
                    fontSize: '16px',
                    cursor: 'pointer',
                  }}
                >
                  {book.title}
                </a>
                <p style={{ color: '#555', marginBottom: '5px' }}>
                  written by {book.authors || 'Author is not available'}, published by {book.publisher || 'Publisher is not available'}
                </p>
              </div>
            </div>
            <div style={{ display: 'flex', left: 'space-around', marginTop: '10px' }}>
            <button onClick={() => onTitleClick(book)} style={{ ...buttonStyle, backgroundColor: 'darkblue' }}>
                Read Book
              </button>&nbsp;&nbsp;
            <button onClick={() => handleExplore(book)} style={{ ...buttonStyle, backgroundColor: 'darkblue' }}>
                Summary
              </button>&nbsp;&nbsp;
              <button onClick={() => handleQA(book)} style={{ ...buttonStyle, backgroundColor: 'darkblue' }}>
                Q&A
              </button>&nbsp;&nbsp;
              <button onClick={() => handleTranslate(book)} style={{ ...buttonStyle, backgroundColor: 'darkblue' }}>
                Translate
              </button>&nbsp;&nbsp;
              <button onClick={() => handleSearchRefine(book)} style={{ ...buttonStyle, backgroundColor: 'darkblue' }}>
                Search Refinement
              </button>
            </div>
          </div>
        ))}
      </div>
    ));
  };
  
  const buttonStyle = {
    padding: '5px 10px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };


  const handleQA = async (book) => {
    const question = prompt("Enter your question about the book:");
    if (!question) return;
    const answer = await answerBookQuery(`${question}\nBook: ${book.title}`);
    alert(`Answer:\n${answer}`);
  };

  const handleTranslate = async (book) => {
    const language = prompt("Enter the target language (e.g., Hindi, Kannada, French):");
    if (!language || language.trim() === '') {
      alert("Please enter a valid language.");
      return;
    }
  
    const summary = await suggestRelatedContent(book); // Generate the summary using the existing summary function
    const translation = await translateBookDetails(book.title, summary, language.trim());
  
    alert(`Translated Details:\n${translation}`);
  };

  const handleExplore = async (book) => {
    const relatedContent = await suggestRelatedContent(book.title);
    alert(`Related Content:\n${relatedContent}`);
  };

  const handleSearchRefine = async (book) => {
    const refinedQuery = await refineSearchQuery(book.title);
    alert(`Refined Search Query:\n${refinedQuery}`);
  };



  const answerBookQuery = async (book) => {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4',
        messages: [
          { role: 'system', content: 'Summarize the book details.' },
          { role: 'user', content: `Book Details: ${JSON.stringify(book)}` },
        ],
      },
      { headers: { Authorization: `Bearer ${OPENAI_API_KEY}`, 'Content-Type': 'application/json' } }
    );
    return response.data.choices[0].message.content.trim();
  };

  const translateBookDetails = async (title, summary, targetLanguage) => {
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4',
          messages: [
            {
              role: 'system',
              content: 'You are a translation assistant. Translate the given book title and summary into the specified language.',
            },
            {
              role: 'user',
              content: `Book Title: "${title}"\nSummary: "${summary}"\nTarget Language: "${targetLanguage}"`,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      const translation = response.data.choices[0].message.content.trim();
      return translation;
    } catch (error) {
      console.error('Error translating text:', error.message);
      return 'Translation failed. Please try again.';
    }
  };

  const suggestRelatedContent = async (book) => {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4',
        messages: [
          { role: 'system', content: 'Summarize the book details.' },
          { role: 'user', content: `Book Details: ${JSON.stringify(book)}` },
        ],
      },
      { headers: { Authorization: `Bearer ${OPENAI_API_KEY}`, 'Content-Type': 'application/json' } }
    );
    return response.data.choices[0].message.content.trim();
  };

  const refineSearchQuery = async (book) => {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4',
        messages: [
          { role: 'system', content: 'Summarize the book details.' },
          { role: 'user', content: `Book Details: ${JSON.stringify(book)}` },
        ],
      },
      { headers: { Authorization: `Bearer ${OPENAI_API_KEY}`, 'Content-Type': 'application/json' } }
    );
    return response.data.choices[0].message.content.trim();
  };


  const onTitleClick = (book) => {
    const userLoggedIn = localStorage.getItem('token'); // Check if user is logged in
  
    if (!userLoggedIn) {
      // If not logged in, open the login modal
      localStorage.setItem('redirectAfterLogin', JSON.stringify(book)); // Store the book details for post-login redirect
      setIsLoginModalOpen(true); // Open login modal
      return;
    }
  
    // If logged in, open the modal with book details
    setSelectedArticleId(book.link.split('/').pop()); // Extract and set the book ID
    setModalOpen(true); // Open the modal
  };
  

  // Handle user messages
  const sendMessageToAssistant = async (message) => {
    try {
      const searchTerms = await processQueryWithOpenAI(message);
  
      if (!searchTerms) {
        return 'I couldn’t understand your query. Can you please rephrase?';
      }
  
      const recommendations = await getRecommendations(searchTerms);
  
      // Combine search terms into a single sentence
      const searchTerms1 = searchTerms
      .split(/,|\n|-/) // Split by common delimiters
      .map((term) => term.trim()) // Remove extra spaces
      .filter((term) => term.length > 0); // Remove empty terms

          // Format the search terms for display with commas and spaces
    const formattedSearchTerms = searchTerms1.join(', ');
  
      const searchTermsHeader = (
        <p>
           Based on your query, AI Assistant has extracted the following semantic search terms and is showing the results from UPSIFS Digital Library:<strong> {formattedSearchTerms}
          </strong>
        </p>
      );
  
      const formattedRecommendations = formatRecommendations(recommendations);
  
      return (
        <div>
          {searchTermsHeader}
          {formattedRecommendations}
        </div>
      );
    } catch (error) {
      console.error('Error generating response:', error.message);
      return <p>An error occurred while processing your request.</p>;
    }
  };



  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!userMessage.trim()) return;

    const userMessageObj = { role: 'user', content: userMessage };
    setMessages((prev) => [...prev, userMessageObj]);

    const assistantReply = await sendMessageToAssistant(userMessage);
    const assistantMessageObj = { role: 'assistant', content: assistantReply };

    setMessages((prev) => [...prev, assistantMessageObj]);
    setUserMessage('');
  };

  return (
    <div style={{ maxWidth: '80%', margin: '0 auto', padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <br></br><br></br>
      <h4 style={{ color: 'darkblue' }}>UPSIFS Digital Library: AI Assistant</h4>
      <div
        style={{
          border: '1px solid #ddd',
          borderRadius: '5px',
          padding: '10px',
          height: '270px',
          overflowY: 'auto',
          marginBottom: '10px',
        }}
      >
        {/* Add your AI assistant chat UI here */}
        <p>
          <strong>Greeting Message:</strong>
        </p>
        <p>
                Hello! 👋 Welcome to the Digital Library of the Uttar Pradesh
                State Institute of Forensic Sciences! This AI Assiatnt Chat option 
                provides you to interact with the digital library collection,
                 including the asking any question, suggested books on any topic, 
                 etc. It can give you best answer for any question!
        </p>
        {messages.map((msg, index) => (
          <div
            key={index}
            style={{
              textAlign: msg.role === 'user' ? 'right' : 'left',
              color: msg.role === 'user' ? 'blue' : 'green',
              marginBottom: '10px',
            }}
          >
            {msg.role === 'user' ? (
              `You: ${msg.content}`
            ) : (
              <div>
                UPSIFS AI Assistant: {typeof msg.content === 'string' ? msg.content : msg.content}
              </div>
            )}
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit} style={{ display: 'flex', gap: '10px' }}>
        <input
          type="text"
          value={userMessage}
          onChange={(e) => setUserMessage(e.target.value)}
          placeholder="Type your query..."
          style={{
            flex: '1',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ddd',
            borderColor: 'red',
            height: '40px',
          }}
        />
        <button
          type="submit"
          style={{
            padding: '10px 20px',
            backgroundColor: 'darkblue',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Send
        </button>
      </form>

      <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="article-details-title"
          aria-describedby="article-details-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              right: '1%',
              transform: 'translate(0%, -50%)',
              width: '50%',
              bgcolor: '#f0fafa',
              boxShadow: 24,
              p: 1,
              overflow: 'auto',
              maxHeight: '100vh',
              [theme.breakpoints.down('md')]: {
                width: '90%', // Width adjustment for medium screens
              },
              [theme.breakpoints.down('sm')]: {
                width: '90%', // Width adjustment for small screens
                maxHeight: '70vh', // Adjust height for smaller screens
              },
            }}
          >
            { (
              <ArticleDetailsDSpace
                selectedArticleId={selectedArticleId} // Pass selected article ID
                closeModal={() => setModalOpen(false)} // Function to close the modal
              />
            )}

          </Box>
        </Modal>

        <LoginModal
          open={isLoginModalOpen}
          onClose={() => setIsLoginModalOpen(false)}
          onLoginSuccess={() => {
            setIsLoginModalOpen(false);
            const redirectInfo = JSON.parse(localStorage.getItem('redirectAfterLogin'));
            if (redirectInfo) {
              localStorage.removeItem('redirectAfterLogin');
              onTitleClick(redirectInfo); // Retry the title click logic after login
            }
          }}
        />
    </div>
  );
};

export default AI_Assistant;
