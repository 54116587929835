import React, { useState } from 'react';
import MicIcon from '@mui/icons-material/Mic';
import './SearchBox1.css';
import { useNavigate } from 'react-router-dom';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = SpeechRecognition ? new SpeechRecognition() : null;

const SearchBox = ({ onSearch, initialQuery }) => {
  const [inputValue, setInputValue] = useState(initialQuery || '');
  const [isListening, setIsListening] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();


  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSearch = (searchQuery) => {
    const query = (searchQuery || inputValue || '').toString().trim();
    if (!query) {
      setError('Search query cannot be empty.');
      return;
    }
    setError(null); // Clear previous errors
    if (typeof onSearch === 'function') {
      onSearch(query); // Call onSearch if it is a valid function
    } else {
      navigate(`/search-results?query=${encodeURIComponent(query)}`); // Example redirection
    }
  };

  const handleClear = () => {
    setInputValue('');
    setError(null);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const startListening = () => {
    if (recognition) {
      recognition.start();
      setIsListening(true);
    }
  };

  const stopListening = () => {
    if (recognition) {
      recognition.stop();
      setIsListening(false);
    }
  };

  if (recognition) {
    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setInputValue(transcript);
      onSearch(transcript);
      handleSearch(transcript);
    };

    recognition.onerror = () => {
      setIsListening(false);
    };
  }

  return (
        <div className="content-wrapper">

          {/* Search Box */}
          <div className="search-box1">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder="Enter Search Term ..."
              className="search-input1"
            />
            <button
              onMouseDown={startListening}
              onMouseUp={stopListening}
              onTouchStart={startListening}
              onTouchEnd={stopListening}
              className={`voice-btn1 ${isListening ? 'listening' : ''}`}
              aria-label="Voice Search"
            >
              <MicIcon />
            </button>
            {inputValue && (
              <button onClick={handleClear} className="clear-btn1">
                ✖
              </button>
            )}
            <button onClick={() => handleSearch()} className="search-btn1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="search-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-4.35-4.35M18 11a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </button>
          </div>
        </div>
  );
};

export default SearchBox;
