import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/Home';
import SingleSearch from './pages/SingleSearch';
import EBooks from './pages/EBooks';
import EJournals from './pages/EJournals';
import Journal_Articles from './pages/Journal_Articles';
import Leisure_Readings from './pages/Leisure_Readings';
import AI_Assistant from './pages/AI_Assistant';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import ArticleDetailsDSpace from './pages/ArticleDetails_DSpace';

const App = () => {

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/single-search" element={<SingleSearch />} />
        <Route path="/EBooks" element={<EBooks />} />
        <Route path="/EJournals" element={<EJournals />} />
        <Route path="/Journal_Articles" element={<Journal_Articles />} />
        <Route path="/Leisure_Readings" element={<Leisure_Readings />} />
        <Route path="/AI_Assistant" element={<AI_Assistant />} />
        <Route path="/article/dspace/:id" element={<ArticleDetailsDSpace />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/ContactUs" element={<ContactUs />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
