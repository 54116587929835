// File path: src/pages/Home.js

import React from 'react';
import SearchComponent from '../components/SearchBox';
import CardGrid from '../components/Cardgrid';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  const handleSearch = (searchQuery) => {
    if (searchQuery) {
      navigate(`/single-search?query=${encodeURIComponent(searchQuery)}`);
    }
  };

  return (

      <div style={{ backgroundColor: '#FFF2F2', minHeight: '100vh' }} className="flex flex-col">

      <main className="flex-grow p-4">
      <SearchComponent onSearch={handleSearch} />
        <CardGrid />
      </main>
      </div >
  );
};

export default Home;
