import React, { useState } from 'react';
import { Paper, Typography, Button } from '@mui/material';
import axios from 'axios';

const OpenAIResponse = ({ query }) => {
  const [aiResponse, setAiResponse] = useState('');
  const [shortenedResponse, setShortenedResponse] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);

  const truncateText = (text, maxLength) => {
    if (!text || text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  // Fetch AI Response
  const fetchAIResponse = async (searchQuery) => {
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            { role: 'system', content: 'You are a helpful assistant that provides detailed answers.' },
            { role: 'user', content: searchQuery },
          ],
          max_tokens: 1000,
          n: 1,
          stop: null,
          temperature: 0.7,
        },
        {
          headers: {
          'Authorization': `Bearer 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`,
          'Content-Type': 'application/json'
          },
        }
      );

      if (response.data && response.data.choices?.length > 0) {
        const fullResponse = response.data.choices[0].message.content.trim();
        setAiResponse(fullResponse);
        setShortenedResponse(truncateText(fullResponse, 300));
      } else {
        console.error('AI response format is unexpected:', response.data);
        setAiResponse('No AI response available.');
        setShortenedResponse('No AI response available.');
      }
    } catch (error) {
      console.error('Error fetching AI response:', error);
      setAiResponse('Error fetching AI response. Please try again.');
      setShortenedResponse('Error fetching AI response. Please try again.');
    }
  };

  // Fetch response when the query changes
  React.useEffect(() => {
    if (query) {
      fetchAIResponse(query);
    }
  }, [query]);

  return (
    <Paper elevation={1} sx={{ p: 2, mt: 1, backgroundColor: '#FFCCCC' }}>
      <Typography variant="subtitle1" sx={{ fontSize: '12px', color: 'darkblue' }}>
           <b>AI Assistant Answer</b>
      </Typography>
      <Typography variant="body1" sx={{ fontSize: '14px', color: 'darkblue' }}>
        {isExpanded ? aiResponse : shortenedResponse}
      </Typography>
      {aiResponse.length > 300 && (
        <Button
          onClick={toggleReadMore}
          sx={{
            backgroundColor: 'red',
            fontSize: '14px',
            color: 'white',
            '&:hover': {
              backgroundColor: 'darkblue',
            },
          }}
        >
          {isExpanded ? 'Read Less' : 'Read More'}
        </Button>
      )}
    </Paper>
  );
};

export default OpenAIResponse;