import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import SearchBox1 from '../components/SearchBox1';
import SearchResults from '../components/SearchResults_Journals';
import Pagination from '../components/Pagination';
import SortBy from '../components/SortBy';
import Facets from '../components/Facets';
import ListIcon from '@mui/icons-material/ViewList';
import GridIcon from '@mui/icons-material/ViewModule';
import LoginModal from '../components/LoginModal';
import fetchJGateResults from '../components/fetchJGateResults_Journals';
import { useTheme } from '@mui/material/styles';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

import { Doughnut } from 'react-chartjs-2';
import ArticleDetailsDSpace from '../pages/ArticleDetails_DSpace'; // Import the ArticleDetailsDSpace component


import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  Modal,
  Paper,
  IconButton, 
  Tabs,
  Tab,
  useMediaQuery,
  MenuItem,
  Select,
} from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';



// Register the required components
ChartJS.register(ArcElement, Tooltip, Legend);  




// Function to convert search results to CSV format
const convertResultsToCSV = (results) => {
  const headers = ['Title', 'Abstract', 'Authors', 'Year', 'Source'];
  const rows = results.map(result => [
    `"${result.title}"`,
    `"${result.abstract}"`,
    `"${result.authors}"`,
    `"${result.year}"`,
    `"${result.source}"`,
  ]);

  const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
  return csvContent;
};

// Function to handle download
const handleDownload = (results) => {
  const csvData = convertResultsToCSV(results);
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'search_results.csv';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};



const EJournals = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isMobile = useMediaQuery('(max-width:768px)'); // Adjust breakpoint as needed
  const [tabValue, setTabValue] = useState(0);
  

  const queryFromUrl = new URLSearchParams(location.search).get('query') || '';
  const [query, setQuery] = useState(queryFromUrl);
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false); // State to control modal open/close
  const [selectedArticleId, setSelectedArticleId] = useState(null); // State to store selected article ID
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [viewMode, setViewMode] = useState('list');
  const [sortOrder, setSortOrder] = useState('sjr'); // Default to 'sjr'
  const [selectedSort, setSelectedSort] = useState({ field: 'score', direction: 'DESC' });
  const [selectedFilters, setSelectedFilters] = useState({});

  const theme = useTheme(); // Access the theme

    // Check if user is logged in using localStorage
    const userLoggedIn = localStorage.getItem('token') ? true : false;
    
    const [facets, setFacets] = useState({
      subjects: {},
      publishers: {},
      countries: {}
    });




  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  

  const resultsPerPage = 20; // Define results per page


 
  const [showSortMenu, setShowSortMenu] = useState(false);

  const toggleSortMenu = () => {
    setShowSortMenu(prev => !prev);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

 

  const fetchResults = useCallback(async (searchQuery, pageNumber = 1, filters = {}, sorting = selectedSort) => {
    setLoading(true);
    setError(null);
  
    try {
      const filterParams = Object.keys(filters).reduce((acc, key) => {
        const value = filters[key];
        if (Array.isArray(value)) {
          acc[key] = value.join(','); // Convert array to comma-separated values
        } else if (value) {
          acc[key] = value; // If it's a single value, assign directly
        }
        return acc;
      }, {});
  
      console.log('Filter Params Sent to API:', filterParams); // Debug filter parameters
  
      const { results, total, facets } = await fetchJGateResults(searchQuery, pageNumber, filterParams, sorting);
      setResults(results);
      setTotalResults(total);
      setFacets(facets);
  
      setTotalPages(Math.ceil(total / resultsPerPage));
    } catch (error) {
      console.error('Error fetching results:', error);
      setError('Failed to fetch journal results. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [selectedSort, setResults, setTotalResults, setFacets]);
  


  const handleFacetSelection = (facetName, value) => {
    const newFilters = { ...selectedFilters };
  
    // Add or remove selected filters
    if (newFilters[facetName]?.includes(value)) {
      // Remove value if it is already selected
      newFilters[facetName] = newFilters[facetName].filter((item) => item !== value);
      if (newFilters[facetName].length === 0) delete newFilters[facetName];
    } else {
      // Add value if it is not already selected
      newFilters[facetName] = [...(newFilters[facetName] || []), value];
    }
  
    console.log('Updated Filters:', newFilters); // Debugging
    setSelectedFilters(newFilters);
  
    // Fetch updated results based on selected filters
    fetchResults(query, 1, newFilters);
  };
  

  useEffect(() => {
    const fetchAndSetData = async () => {
      try {
        const { results, total, facets } = await fetchJGateResults(query, page, selectedFilters);
        console.log('Results:', results); // Debugging
        console.log('Facets:', facets); // Debugging
        setResults(results);
        setTotalResults(total);
        setFacets(facets);
      } catch (error) {
        console.error('Error fetching results:', error);
      }
    };
    fetchAndSetData();
  }, [query, page, selectedFilters]);

  

  const calculateRange = () => {
    const startIndex = (page - 1) * resultsPerPage + 1;
    const endIndex = Math.min(page * resultsPerPage, totalResults); // Use totalResults for the upper bound
    return totalResults > 0 ? `${startIndex}-${endIndex}` : '0'; // Ensure it doesn't show "1-0"
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchResults(query, newPage, selectedFilters);
  };
  
  const handleSortChange = (newSortOrder) => {
    let sortField, sortDirection;
  
    switch (newSortOrder) {
      case 'sjr':
        sortField = 'sjr';
        sortDirection = 'DESC'; // Assuming descending order for SJR
        break;
      case 'h-index':
        sortField = 'hIndex';
        sortDirection = 'DESC'; // Assuming descending order for H-Index
        break;
      default:
        sortField = 'defaultField'; // Fallback field
        sortDirection = 'ASC'; // Default direction
        break;
    }
  
    setSelectedSort({ field: sortField, direction: sortDirection });
    fetchResults(query, page, selectedFilters, { field: sortField, direction: sortDirection });
  };

  
// Refetch results when query or page changes
useEffect(() => {
  fetchResults(query, page, selectedFilters);
}, [fetchResults, query, page, selectedFilters]);

 



  

 


  const StyledContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(4),
  }));


  const SearchResultsWrapper = styled(Box)(({ theme }) => ({
    width: '70%', // Default for larger screens
    paddingLeft: theme.spacing(2),
    minHeight: '500px',
    [theme.breakpoints.down('md')]: {
      width: '100%', // Full width on medium screens or smaller
      paddingLeft: 0,
    },
  }));

  const YouTubeResultsWrapper = styled(Box)(({ theme }) => ({
    width: '30%', // Default for larger screens
    paddingLeft: theme.spacing(2),
    minHeight: '500px',
    [theme.breakpoints.down('md')]: {
      width: '100%', // Full width on medium screens or smaller
      paddingLeft: 0,
    },
  }));


  // Function to handle title click
  const onTitleClick = (result) => {
    window.open(result.detailLink, '_blank'); // Opens in a new tab
  };


const handleLoginSuccess = () => {
  setIsLoginModalOpen(false);
  localStorage.setItem('token', 'user-token'); // Save token (mocking login success)
  
  const redirectInfo = JSON.parse(localStorage.getItem('redirectAfterLogin'));
  if (redirectInfo) {
    localStorage.removeItem('redirectAfterLogin'); // Clear the stored redirect info

    // Redirect to the stored action
    if (redirectInfo.source === 'eJournal Articles') {
      window.open(redirectInfo.detailLink, '_blank');
    } else if (['eBooks', 'Leisure Readings', 'Forensic Science eBooks', 'Law eBooks', 'Cyber Security eBooks'].includes(redirectInfo.source)) {
      const id = redirectInfo.detailLink.split('/').pop();
      setSelectedArticleId(id);
      setArticleSource('eBooks');
      setModalOpen(true);
    }
  } else {
    window.location.reload(); // Reload to refresh state
  }
};




const [articleSource, setArticleSource] = useState(''); // Track article source type


  return (
    <div style={{ backgroundColor: '#FFE6E6', minHeight: '100vh', minWidth: '100vh' }} className="flex flex-col">
    <StyledContainer>
    
    <SearchBox1
      onSearch={(searchQuery) => {
        const sanitizedQuery = (searchQuery || '*').toString().trim();
        setQuery(sanitizedQuery); // Update query state with sanitized value
        fetchResults(sanitizedQuery, 1); // Fetch results with the sanitized query
      }}
      initialQuery={query || '*'}
    />

      <br></br>

    {loading && <CircularProgress />}
      {error && (
        <Snackbar open autoHideDuration={6000}>
        </Snackbar>
      )}      

{isMobile ? (
          <>
            {/* Tabs for mobile view */}
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="Search Results" />
              <Tab label="Filter Results" />
            </Tabs>

            {tabValue === 0 && (
              <SearchResultsWrapper>
                <SearchResults
                  results={results}
                  viewMode={viewMode}
                  onTitleClick={(result) => window.open(result.detailLink, '_blank')}
                />
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </SearchResultsWrapper>
            )}
            {tabValue === 1 && (
              <YouTubeResultsWrapper>
                <Paper elevation={1} sx={{ p: 2, mt: 1, backgroundColor: '#FFCCCC' }}>
                <Typography variant="h6">Filter by:</Typography>

                  {/* Subjects */}
                  <Typography variant="subtitle1">Subjects</Typography>
                  {facets.subjects && Object.entries(facets.subjects).map(([subject, count]) => (
                    <div key={subject}>
                      <input
                        type="checkbox"
                        id={`subject-${subject}`}
                        checked={selectedFilters.subjects_name_l3?.includes(subject) || false}
                        onChange={() => handleFacetSelection('subjects_name_l3', subject)}
                      />
                      <label htmlFor={`subject-${subject}`}>
                        {subject} ({count})
                      </label>
                    </div>
                  ))}

                  {/* Publishers */}
                  <Typography variant="subtitle1">Publishers</Typography>
                  {facets.publishers && Object.entries(facets.publishers).map(([publisher, count]) => (
                    <div key={publisher}>
                      <input
                        type="checkbox"
                        id={`publisher-${publisher}`}
                        checked={selectedFilters.publisher_name?.includes(publisher) || false}
                        onChange={() => handleFacetSelection('publisher_name', publisher)}
                      />
                      <label htmlFor={`publisher-${publisher}`}>
                        {publisher} ({count})
                      </label>
                    </div>
                  ))}

                  {/* Countries */}
                  <Typography variant="subtitle1">Countries</Typography>
                  {facets.countries && Object.entries(facets.countries).map(([country, count]) => (
                    <div key={country}>
                      <input
                        type="checkbox"
                        id={`country-${country}`}
                        checked={selectedFilters.primary_publisher_country?.includes(country) || false}
                        onChange={() => handleFacetSelection('primary_publisher_country', country)}
                      />
                      <label htmlFor={`country-${country}`}>
                        {country} ({count})
                      </label>
                    </div>
                  ))}

                </Paper>


              </YouTubeResultsWrapper>
            )}
          </>
        ) : ( 
        <Box className="main-content" display="flex">

        <SearchResultsWrapper>

          <Paper elevation={1} sx={{ p: 2, mt: 1, backgroundColor: '#FFCCCC' }}>


          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              whiteSpace: 'nowrap',
              paddingBottom: '2px',
              gap: '2px',
            }}
          >
        <Typography variant="subtitle1" sx={{ fontSize: '12px', color: 'darkblue' }}>
          <b>{`Showing ${calculateRange()} of ${totalResults} results`}</b>
        </Typography>


        <Box sx={{ position: 'relative', zIndex: 1000 }}> {/* Increase zIndex */}


        <Select
          value={sortOrder} // Ensure this is a valid value (e.g., 'sjr' or 'h-index')
          onChange={(event) => handleSortChange(event.target.value)}
        >
          <MenuItem value="sjr">Sort by SJR</MenuItem>
          <MenuItem value="h-index">Sort by H-Index</MenuItem>
        </Select>


            
            
            
            <IconButton onClick={() => handleDownload(filteredResults)}>
              <DownloadIcon />
            </IconButton>

            <IconButton onClick={() => setViewMode('list')}>
              <ListIcon />
            </IconButton>
            <IconButton onClick={() => setViewMode('grid')}>
              <GridIcon />
            </IconButton>

            <IconButton>
              <MoreVertIcon />
            </IconButton>
        </Box>
      </Box>
      
      </Paper>

      

      <Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />

      <Box className="main-content" display="flex">
      <SearchResults
        results={results}
        viewMode={viewMode}
        onTitleClick={(result) => window.open(result.detailLink, '_blank')}
      />
      </Box>

      <Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />

      </SearchResultsWrapper>
      

        {/* Right Sidebar */}
        <YouTubeResultsWrapper>

          {/* Databases Facets */}
          <Paper elevation={1} sx={{ p: 2, mt: 1, backgroundColor: '#FFCCCC' }}>
          <Typography variant="h6">Filter by:</Typography>

            {/* Subjects */}
            <Typography variant="subtitle1">Subjects</Typography>
            {facets.subjects && Object.entries(facets.subjects).map(([subject, count]) => (
              <div key={subject}>
                <input
                  type="checkbox"
                  id={`subject-${subject}`}
                  checked={selectedFilters.subjects_name_l3?.includes(subject) || false}
                  onChange={() => handleFacetSelection('subjects_name_l3', subject)}
                />
                <label htmlFor={`subject-${subject}`}>
                  {subject} ({count})
                </label>
              </div>
            ))}

            {/* Publishers */}
            <Typography variant="subtitle1">Publishers</Typography>
            {facets.publishers && Object.entries(facets.publishers).map(([publisher, count]) => (
              <div key={publisher}>
                <input
                  type="checkbox"
                  id={`publisher-${publisher}`}
                  checked={selectedFilters.publisher_name?.includes(publisher) || false}
                  onChange={() => handleFacetSelection('publisher_name', publisher)}
                />
                <label htmlFor={`publisher-${publisher}`}>
                  {publisher} ({count})
                </label>
              </div>
            ))}

            {/* Countries */}
            <Typography variant="subtitle1">Countries</Typography>
            {facets.countries && Object.entries(facets.countries).map(([country, count]) => (
              <div key={country}>
                <input
                  type="checkbox"
                  id={`country-${country}`}
                  checked={selectedFilters.primary_publisher_country?.includes(country) || false}
                  onChange={() => handleFacetSelection('primary_publisher_country', country)}
                />
                <label htmlFor={`country-${country}`}>
                  {country} ({count})
                </label>
              </div>
            ))}


            </Paper>

          </YouTubeResultsWrapper>


        

         <LoginModal
          open={isLoginModalOpen}
          onClose={() => setIsLoginModalOpen(false)}
          onLoginSuccess={handleLoginSuccess}
        />

       <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="article-details-title"
          aria-describedby="article-details-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              right: '1%',
              transform: 'translate(0%, -50%)',
              width: '50%',
              bgcolor: '#f0fafa',
              boxShadow: 24,
              p: 1,
              overflow: 'auto',
              maxHeight: '100vh',
              [theme.breakpoints.down('md')]: {
                width: '90%', // Width adjustment for medium screens
              },
              [theme.breakpoints.down('sm')]: {
                width: '90%', // Width adjustment for small screens
                maxHeight: '70vh', // Adjust height for smaller screens
              },
            }}
          >
            {articleSource === 'eBooks' && (
              <ArticleDetailsDSpace
                selectedArticleId={selectedArticleId} // Pass selected article ID
                closeModal={() => setModalOpen(false)} // Function to close the modal
              />
            )}

          </Box>
        </Modal>
        </Box>
        )}
    </StyledContainer>
    </div>
  );
};

EJournals.propTypes = {
  query: PropTypes.string.isRequired,
};

export default EJournals;
